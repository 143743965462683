export enum ALLOWED_BUTTON_SIZES {
    LARGE = 0,
    MEDIUM,
    SMALL,
}

export enum ALLOWED_BUTTON_TYPES {
    PRIMARY = 0,
    SECONDARY,
    PRIMARY_HOVER,
    SECONDARY_HOVER,
    LINK,
}

export enum ALLOWED_TEXT_ALIGNMENTS {
    RIGHT = 0,
    CENTER,
    LEFT,
}

export enum ALLOWED_BUTTON_COLORS {
    BLACK = 0,
    BLUE,
    BROWN,
    WHITE,
    INACTIVE,
}

export type ButtonSizes = typeof ALLOWED_BUTTON_SIZES[number];

export type ButtonTypes = typeof ALLOWED_BUTTON_TYPES[number];

export type ButtonColors = typeof ALLOWED_BUTTON_COLORS[number];

export type TextAlignments = typeof ALLOWED_TEXT_ALIGNMENTS[number];
