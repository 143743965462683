import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationInsightsService } from '@core/application-insights.service';
import { AuthenticationService } from '@core/authentication.service';
import { AccountInformation } from '@models/interfaces';
import { ShSelectComponent } from '@shared/sh-select/sh-select.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @ViewChild(ShSelectComponent) private selectComponent: ShSelectComponent;

    public authenticated$ = this.auth.isLoggedIn;
    public user?: AccountInformation;
    public gravatarUrl: string;
    public gravatarUrlRetina: string;
    public isMenuOpen = false;

    constructor(
        private readonly aiService: ApplicationInsightsService,
        private readonly auth: AuthenticationService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.auth.accountObs.pipe(takeUntil(this.unsubscribe)).subscribe(account => {
            if (account) {
                this.user = account;
                if (this.user.emailHash) {
                    this.gravatarUrl = `https://www.gravatar.com/avatar/${this.user.emailHash}?s=32&d=identicon`;
                    this.gravatarUrlRetina = `https://www.gravatar.com/avatar/${this.user.emailHash}?s=64&d=identicon`;
                }
                // For names longer than 16 characters, only include first name
                if (this.user?.name?.length > 16) {
                    this.user.name = this.user.name.split(' ')[0].substr(0, 16);
                }
                this.cd.markForCheck();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public showDropdown() {
        if (this.selectComponent) {
            this.selectComponent.showDropdown();
        }
    }

    public handleMenu(event?: FocusEvent) {
        if (event?.relatedTarget) {
            return;
        } else {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }

    public handleLogin() {
        console.log('Logging in');
        this.auth.login();
    }

    public handleLogout() {
        console.log('Logging out');
        this.auth.logout();
        this.aiService.clearAuthenticatedUserContext();
    }
}
