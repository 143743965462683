<div #productPage class="product-page" *ngIf="tabData">
    <div class="product-page__header">
        <a class="product-page__close-button" (click)="closeDialog()">
            <span>X</span>
        </a>
        <h1 class="product-page__title">{{ data?.name }}</h1>
        <app-sh-tabs (tabContent)="onTabData($event)" [tabData]="tabData"></app-sh-tabs>
    </div>
    <div class="product-page__content">
        <app-sh-panel>
            <app-sh-panel-content [ngSwitch]="activeTab?.component">
                <div *ngSwitchCase="'table'">
                    <app-sh-table
                        [tableModel]="activeTab?.tabData"
                        idField="id"
                        isTransparentHeader="true"
                        [disabled]="true"
                        (linkClicked)="onLinkClicked($event)"
                    >
                        <ng-template appTableCellContent="iconTpl" let-row="row">
                            <div class="product-page__icons">
                                <img alt [attr.src]="icon.src" [attr.title]="icon.title" *ngFor="let icon of row" />
                            </div>
                        </ng-template>
                    </app-sh-table>
                </div>

                <div *ngSwitchCase="'text'">
                    <h1 class="text__heading--paragraph">
                        {{ activeTab?.tabData?.title }}
                    </h1>
                    <p class="text__paragraph">
                        {{ activeTab?.tabData?.description }}
                    </p>
                </div>

                <ng-container *ngSwitchCase="'productProfile'">
                    <div class="product-page__profiles">
                        <select name="productProfileFileLanguage" id="productProfileFileLanguage" class="product-page__profiles-select" (change)="setProductProfileLanguage($event.target.value)">
                            <ng-container *ngFor="let link of sortLanguages(filteredProductFiles)">
                                <option [label]="link.label" [value]="link.label"></option>
                            </ng-container>
                        </select>
                        <button class="product-page__profiles-download-button" (click)="downloadFile()" mat-fab color="warn">
                            <span class="sh-document-viewer__download-button-icon" [inlineSVG]="'./assets/icons/new-download.svg'" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="product-page__container">
                        <div class="text__paragraph-container text__paragraph-container--slim">
                            <mat-spinner *ngIf="isLoadingPdf" class="sh-document-viewer__spinner"></mat-spinner>
                            <ng-container *ngIf="fileLink && fileLink.href">
                                <pdf-viewer [src]="fileLink.href" [style]="{width: '100%', height: '100%'}" (after-load-complete)="loadComplete($event)"></pdf-viewer>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </app-sh-panel-content>
        </app-sh-panel>
    </div>
</div>
<div #productPage class="product-page" *ngIf="!tabData">
    <div class="product-page__header">
        <a class="product-page__close-button" (click)="closeDialog()">
            <span>X</span>
        </a>
        <h1 class="product-page__title">{{ data?.name }}</h1>
        There is no data for this product.
    </div>
</div>
