import { Component, Input, HostBinding } from '@angular/core';

enum ALLOWED_PANEL_TYPES {
    LIGHT = 0,
    DARK = 1,
    TRANSPARENT = 2,
}

export type PanelTypes = typeof ALLOWED_PANEL_TYPES[number];

@Component({
    selector: 'app-sh-panel',
    template: `
        <ng-content select="app-sh-panel-header"></ng-content>
        <ng-content select="app-sh-panel-content"></ng-content>
    `,
})
export class ShPanelComponent {
    @Input() public type: PanelTypes = 'light';

    @HostBinding('class') get classes(): string {
        const classes = ['sh-panel__container', `sh-panel__container--${this.type}`];
        return classes.join(' ');
    }
}
