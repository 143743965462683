import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private readonly notification: NotificationService, private readonly auth: AuthenticationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err: unknown) => {
                if (err instanceof HttpErrorResponse) {
                    console.error('API error message', err.status, err.statusText, err.message);

                    switch (err.status) {
                        case 401:
                        case 403:
                            this.notification.showNotification({
                                message: 'Your login session has expired, please login again',
                            });
                            this.auth.clearLogin();
                            break;
                    }
                }

                return throwError(err);
            })
        );
    }
}
