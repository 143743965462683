import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationService } from '@core/authentication.service';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
    public authenticated = this.auth.isLoggedIn;
    constructor(public readonly auth: AuthenticationService) {}
}
