import { ChangeDetectionStrategy, Component, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor } from '@angular/forms';
import { CheckboxValueType } from '@models/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-checkboxes',
    templateUrl: './sh-checkboxes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShCheckboxesComponent implements OnInit, OnDestroy, ControlValueAccessor {
    private readonly unsubscribe = new Subject<void>();

    @Input() public data: CheckboxValueType[];
    @Input() public formControlName: string;
    @Input() public fieldName: string;
    @Input() public checkboxStyle: 'pills' | 'columns';
    @Output() public valueChange = new EventEmitter<string[]>();

    public value: CheckboxValueType;

    private control: AbstractControl;

    constructor(@Optional() @Host() @SkipSelf() private _controlContainer: ControlContainer) {}
    ngOnInit() {
        if (this.formControlName && this._controlContainer.control) {
            const control = this._controlContainer.control.get(this.formControlName);
            if (control) {
                this.control = control;
                this.value = this.control && this.control.value;
                this.control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value: any) => {
                    this.value = value;
                });
            }
        }
        this.fieldName = this.fieldName || 'checkboxes';
    }

    public registerOnChange() {
        this.writeValue();
    }

    public registerOnTouched() {
        this.writeValue();
    }

    public writeValue() {
        const checked = this.data.filter(item => item.checked);
        const values = checked.map(item => item.key);
        this.valueChange.emit(values);
    }
    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
