<app-sh-table
    [tableModel]="tableModel"
    [allowSort]="true"
    [isSelectable]="false"
    [idField]="'product'"
    [hasPaginator]="false"
    [pageSize]="10"
    showFirstLastButtons="true"
    [pageSizeOptions]="[10, 20, 30]"
    isTransparentHeader="true"
>
</app-sh-table>
