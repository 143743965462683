import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FeatureDetectionService {
    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    isServer(): boolean {
        return isPlatformServer(this.platformId);
    }
}
