<app-sh-select
    (click)="showDropdown()"
    (changeEvent)="registerOnChange($event)"
    [selected]="'select'"
    [isBrown]="true"
    [label]="label"
    [ariaLabel]="ariaLabel"
>
    <app-sh-select-option [value]="placeholderOption || 'Select'" key="select" [isBrown]="true"></app-sh-select-option>

    <app-sh-select-option *ngFor="let option of marketingFilesApplicationAreas; trackBy: trackByFn" [key]="option" [value]="option" [isBrown]="true">
    </app-sh-select-option>
</app-sh-select>
