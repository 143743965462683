import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataFilterService } from '@core/data-filter.service';
import { FilterResult, FilterType, QueryType, QueryTypeList } from '@models/interfaces';
import { StorageTypes } from '@models/types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-data-filter',
    templateUrl: './sh-data-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShDataFilterComponent implements OnInit, OnDestroy {
    @Input() public data: StorageTypes[];
    @Input() public filters: FilterType[];
    @Output() public filtered = new EventEmitter<FilterResult>();

    public isSearch = false;
    public query: QueryTypeList = {};
    private filterChanges = new Subject<QueryType>();
    private unsubscribe = new Subject<void>();

    constructor(private readonly dataFilter: DataFilterService, private readonly cdr: ChangeDetectorRef) {}

    public onValueChange(value: string | string[], filter: FilterType, isSearch = false) {
        this.isSearch = isSearch;
        const { method, field, operator } = filter;

        this.filterChanges.next({
            value,
            method,
            field,
            operator,
        });
    }

    ngOnInit() {
        this.filterChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged((_oldChange, newChange) => {
                    if (!this.query[newChange.field]) {
                        return false;
                    }
                    return (
                        this.query[newChange.field].value === newChange.value && this.query[newChange.field].value.length === newChange.value.length
                    );
                }),
                switchMap(change => {
                    this.dataFilter.spinner.next({
                        isActive: true,
                        isSearch: this.isSearch,
                    });

                    if (change.value.length) {
                        this.query[change.field] = change;
                    } else {
                        if (typeof this.query[change.field] !== 'undefined') {
                            // Remove query if empty
                            delete this.query[change.field];
                        }
                    }

                    return this.dataFilter.filter(this.data, this.query);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(result => {
                this.filtered.emit(result);
                this.dataFilter.spinner.next({ isActive: false, isSearch: false });
            });

        this.filters.map(filter => {
            if (filter.dataObserver) {
                filter.dataObserver.pipe(takeUntil(this.unsubscribe)).subscribe((data: FilterType[]) => {
                    filter.data = data.values;
                    this.cdr.markForCheck();
                });
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.filterChanges.complete();
    }
}
