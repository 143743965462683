import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { ShDropdownComponent } from './sh-dropdown.component';
import { ShSelectOptionComponent } from './sh-select-option.component';
import { ShDropdownService } from './sh-select.service';

@Component({
    selector: 'app-sh-select',
    templateUrl: './sh-select.component.html',
    providers: [ShDropdownService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShSelectComponent implements OnChanges, AfterContentInit, ControlValueAccessor {
    @Input() public label?: string;
    @Input() public ariaLabel?: string;
    @Input() public selected?: string | null;
    @Input() public required = false;
    @Input() public disabled = false;
    @Input() public isBrown = false;

    @Output() public changeEvent: EventEmitter<string> = new EventEmitter();
    @Output() public blurEvent = new EventEmitter();
    @Output() public focusEvent = new EventEmitter();

    @ViewChild('input') public input: ElementRef;
    @ViewChild(ShDropdownComponent)
    public dropdown: ShDropdownComponent;

    @ContentChildren(ShSelectOptionComponent)
    public options: QueryList<ShSelectOptionComponent>;

    private keyManager: ActiveDescendantKeyManager<ShSelectOptionComponent>;

    public selectedOption?: ShSelectOptionComponent;
    public displayText?: string;

    constructor(private readonly dropdownService: ShDropdownService, private readonly cd: ChangeDetectorRef) {
        this.dropdownService.register(this);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected && changes.selected.currentValue !== changes.selected.previousValue) {
            this.updateValue();
            this.onChange();
        }
    }

    public ngAfterContentInit(): void {
        this.updateValue();
    }

    public showDropdown(): void {
        this.focusEvent.emit();
        this.dropdown.show();

        if (!this.options.length) {
            return;
        }
    }

    public onDropMenuIconClick(event: UIEvent): void {
        event.stopPropagation();
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.input.nativeElement.click();
        }, 10);
    }

    public selectOption(option: ShSelectOptionComponent): void {
        this.selected = option.key;
        this.selectedOption = option;
        this.displayText = this.selectedOption ? this.selectedOption.value : '';
        this.hideDropdown();
        this.input.nativeElement.focus();
        this.onChange();
    }

    public hideDropdown(): void {
        this.blurEvent.emit();
        this.dropdown.hide();
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
            if (!this.dropdown.showing) {
                this.showDropdown();
                return;
            }

            if (!this.options.length) {
                event.preventDefault();
                return;
            }
        }

        if (event.key === 'Enter' || event.key === ' ') {
            const selectedOption = this.keyManager.activeItem;
            if (selectedOption) {
                this.selectOption(selectedOption);
                // this.selectedOption = selectedOption;
                // this.selected = this.selectedOption.key;
                // this.displayText = this.selectedOption ? this.selectedOption.value : '';
                // this.hideDropdown();
                // this.onChange();
            }
        } else if ((event.key === 'Escape' || event.key === 'Esc') && this.dropdown.showing) {
            this.hideDropdown();
        } else if (event.key === 'ArrowUp' || event.key === 'Up' || event.key === 'ArrowDown' || event.key === 'ArrowRight') {
            this.keyManager.onKeydown(event);
        } else if ((event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') && this.dropdown.showing) {
            event.preventDefault();
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.selected = obj;
    }

    public onTouchedFn(): void {}

    public onChangeFn(value: any): void {
        this.changeEvent.emit(value);
    }

    public onTouched(): void {
        this.onTouchedFn();
    }

    public onChange(): void {
        this.onChangeFn(this.selected);
    }

    private updateValue() {
        this.selectedOption = this.options ? this.options.toArray().find(option => option.key === this.selected) : undefined;
        this.displayText = this.selectedOption ? this.selectedOption.value : '';

        this.cd.markForCheck();
    }
}
