import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TabDataType, TabType } from '@models/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-tabs',
    templateUrl: './sh-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShTabsComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input() public tabData: TabType[] = [];
    @Output() public tabContent = new EventEmitter<TabDataType>();

    public activeTab: TabType;
    public activeTabSubject = new ReplaySubject<TabType>(1);

    public onTabChange(tab: TabType) {
        this.activeTabSubject.next(tab);
        this.tabContent.emit(tab.data);
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        // Output first tab on init.
        this.activeTab = this.tabData[0];
        this.tabContent.emit(this.activeTab.data);
        this.activeTabSubject.pipe(takeUntil(this.unsubscribe)).subscribe(tab => {
            if (tab) {
                this.activeTab = tab;
                this.tabContent.emit(tab.data);
            }
        });
    }
}
