import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@core/authentication.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-frontpage',
    templateUrl: './frontpage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontpageComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly pageTitle: string = 'Welcome to the new MyPalsgaard';
    public authenticated$ = this.auth.isLoggedIn;

    constructor(
        private readonly title: Title,
        private readonly auth: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.title.setTitle(this.pageTitle);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public handleLogin() {
        this.authenticated$
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(authenticated => {
                if (!authenticated) {
                    this.auth.login();
                }
            });
    }
}
