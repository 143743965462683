import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface MenuLink {
    title: string;
    path: string;
    exact: boolean;
    dimmed?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    public menuLinks: MenuLink[] = [
        { title: 'Product catalogue', path: '/products', exact: false },
        { title: 'Solution finder', path: '/solution-finder', exact: false },
        { title: 'Marketing files', path: '/marketing-files', exact: false },
        { title: 'User Guide', path: '/user-guide', exact: true },
    ];

    constructor(private readonly router: Router, private readonly title: Title) {
        this.trackNavigationTitle();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * This will track the page titles for the pages in the menu links
     * @private
     */
    private trackNavigationTitle(): void {
        this.router.events
            .pipe(
                filter(route => route instanceof NavigationEnd),
                takeUntil(this.unsubscribe)
            )
            .subscribe(route => {
                if (!(route instanceof NavigationEnd)) {
                    return;
                }

                // this only handle routes without the query params and make part of the menu links
                const pageTitle = this.getMenuTitle(route.url);
                if (pageTitle) {
                    this.title.setTitle(pageTitle);
                }
            });
    }

    /**
     * Getting the page title for the path
     * @param path
     * @private
     */
    private getMenuTitle(path: string): string | undefined {
        return this.menuLinks.find(link => link.path === path)?.title || '';
    }
}
