<div class="sh-input__container">
    <input
        #input
        class="sh-input__field"
        [disabled]="isDisabled || disabled"
        [value]="value"
        [attr.type]="type"
        [attr.name]="name"
        [attr.id]="id"
        [attr.maxlength]="maxLength"
        [attr.max]="max"
        [attr.min]="min"
        [attr.placeholder]="placeholder || 'Search'"
        [readonly]="isReadonly"
        (blur)="registerOnTouched()"
        (keyup.esc)="onClearSearch()"
        [attr.aria-label]="ariaLabel || placeholder || 'Search'"
    />
    <div class="sh-input__icon-container" [attr.aria-hidden]="value ? 'false' : 'true'">
        <span
            *ngIf="isSpinnerActive"
            class="sh-input__spinner sh-input__icon"
            role="presentation"
            aria-live="assertive"
            aria-label="The search is underway"
            @spinnerEnterLeave
        ></span>
        <span
            *ngIf="value; else searchIcon"
            (click)="onClearSearch()"
            [inlineSVG]="'./assets/icons/search-close.svg'"
            class="sh-input__icon sh-input__clear-search"
            role="button"
            aria-live="polite"
            aria-label="Clear the search field"
        ></span>
        <ng-template #searchIcon>
            <span [inlineSVG]="'./assets/icons/search.svg'" class="sh-input__icon" aria-hidden="true"></span>
        </ng-template>
    </div>
</div>
