import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-sh-table-paginator',
    templateUrl: './sh-table-paginator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShTablePaginatorComponent implements OnChanges {
    @Input() public hidePageSize: boolean;
    @Input() public showFirstLastButtons: boolean;
    @Input() public disabled: boolean;
    @Input() public length: number;
    @Input() public pageIndex: number;
    @Input() public pageSize: number;
    @Input() public pageSizeOptions: number[];

    @Output() public pageChange: EventEmitter<number> = new EventEmitter();
    @Output() public pageSizeChange: EventEmitter<number> = new EventEmitter();

    public pages: number[] = [];
    public minPage = 1;
    public maxPage = 10;

    constructor(private cdr: ChangeDetectorRef) {}

    public ngOnChanges(): void {
        this.pages = this.getArrayOfPage();
        this.hiddenOptions();
        this.cdr.markForCheck();
    }

    public onPageClick(page: number): void {
        this.pageChange.emit(page);
    }

    public hiddenOptions(): void {
        if (this.pageIndex > 5) {
            const tempMinPage = this.pageIndex - 3;
            this.maxPage = tempMinPage + 9 > this.pages.length ? this.pages.length : tempMinPage + 9;

            this.minPage = this.maxPage - tempMinPage < 9 ? this.maxPage - 9 : tempMinPage;
        } else {
            this.minPage = 1;
            this.maxPage = 10;
        }
        this.cdr.detectChanges();
    }

    public firstPage(): void {
        if (this.pageIndex === 1) {
            return;
        }
        this.pageChange.emit(1);
        this.cdr.detectChanges();
    }

    public lastPage(): void {
        if (this.pageIndex === this.length) {
            return;
        }
        this.pageChange.emit(this.length);
        this.cdr.detectChanges();
    }

    public nextPage(): void {
        if (this.pageIndex + 1 > this.pages.length) {
            return;
        }
        this.pageChange.emit(this.pageIndex + 1);
        this.cdr.detectChanges();
    }

    public previousPage(): void {
        if (this.pageIndex - 1 < 1) {
            return;
        }
        this.pageChange.emit(this.pageIndex - 1);
        this.cdr.detectChanges();
    }

    public onPageSizeChange(pageSize: number): void {
        this.pageSizeChange.emit(pageSize);
    }

    private getArrayOfPage(): number[] {
        const pageArray: number[] = [];

        for (let i = 1; i <= this.length; i++) {
            pageArray.push(i);
        }

        return pageArray;
    }
}
