import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FileService } from '@core/file.service';
import { StorageService } from '@core/storage.service';
import { FileBase, RecipeGroupViewModel } from '@models/api';
import { ProductType, RecipeType } from '@models/types';
import { sortBy } from 'lodash-es';
import { DrawerSettingType, SettingService } from '@core/setting.service';

interface ISolutionSorted {
    recipe: RecipeType;
    products: ProductType[];
}

@Component({
    selector: 'app-sh-recipe',
    templateUrl: 'sh-recipe.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShRecipeComponent implements OnChanges {
    @Input() public solution: RecipeGroupViewModel;

    solutionsSorted: Promise<ISolutionSorted[]>;

    constructor(
        private readonly store: StorageService,
        private readonly file: FileService,
        private settingService: SettingService,
    ) {}

    ngOnChanges(): void {
        this.solutionsSorted = this.getSolutions();
    }

    public openRecipePdf(event: MouseEvent, recipeFile: FileBase) {
        if (recipeFile.fileId) {
            this.file.linkOrDownloadFile(recipeFile.fileId).then(fileLink => {
                if (fileLink) {
                    this.settingService._drawerSettingSubject.next({
                        type: DrawerSettingType.documentViewer,
                        data: fileLink,
                        state: true
                    });
                }
            });
        }
        event.preventDefault();
        return false;
    }

    private async getSolutions(): Promise<ISolutionSorted[]> {
        const recipeQueue = this.solution.recipes?.map(async recipe => {
            return {
                recipe,
                products: await this.getProducts(recipe.products || []),
            } as ISolutionSorted;
        });

        if (!recipeQueue) {
            return [];
        }

        let recipesSorted = await Promise.all(recipeQueue);

        // Sort all recipes inside solution by the name of the products joined
        recipesSorted = sortBy(recipesSorted, r => r.products.map(p => p.name).join(''));

        return recipesSorted;
    }

    private async getProducts(products: string[]): Promise<ProductType[]> {
        const productList = await Promise.all(products.map(id => this.store.getProductById(id)));
        return productList.filter(x => !!x);
    }
}
