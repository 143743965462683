import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FeatureDetectionService } from '@core/feature-detection.service';

export interface GtmWindow extends Window {
    dataLayer: any;
    google_tag_manager: any;
}

@Injectable({
    providedIn: 'root'
})
export class GtmTrackingService implements OnDestroy {
    private readonly unsubscribe: Subject<void>;
    private readonly gtmWindow: GtmWindow;
    private readonly initPromise: Promise<boolean>;
    private initResolver?: (gtmActive: boolean) => void;

    constructor(@Inject(DOCUMENT) private document: Document, @Optional() private featureDetectionService?: FeatureDetectionService) {
        this.unsubscribe = new Subject<void>();
        this.gtmWindow = (this.featureDetectionService && this.featureDetectionService.isBrowser() ? window : {}) as GtmWindow;
        this.initPromise = new Promise<boolean>(resolve => {
            this.initResolver = resolve;
        });
        this.initPromise.catch(() => {});
    }

    public initGtm(gtmId: string) {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser() && gtmId) {
            if (!gtmId) {
                this.resolvePromise(false, 'WARNING: No container id was specified for google tag manager. Script load aborted.');
                return;
            }

            // Check if dataLayer has been setup. (Setup is done on-page in <head> to ensure compatibility with various GTM testing tools)
            // window.dataLayer should not be used directly on-page other than this one time though.
            if (!this.gtmWindow.dataLayer) {
                this.resolvePromise(
                    false,
                    'WARNING: GTM dataLayer is not defined. Please ensure that it has been defined in the <head> section.'
                );
                return;
            }

            // Push start info
            this.setGtmStart();

            // Load gtm script
            this.loadScript('https://www.googletagmanager.com/gtm.js?id=' + gtmId, true, () => {
                if (!this.gtmWindow.google_tag_manager) {
                    console.warn('INFO: GTM is being prevented from loading (likely because of an add-blocker).');
                }

                // Resolve promise
                this.resolvePromise(true);
            });
        }
    }

    private resolvePromise(status: boolean, message?: string) {
        if (this.initResolver) {
            this.initResolver(status);
            if (message) {
                console.warn(message);
            }
        }
    }

    private loadScript(url: string, isAsync = true, callback?: (this: GlobalEventHandlers, ev: Event) => any): void {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser()) {
            const doc = this.document;
            const scriptElement: HTMLScriptElement = doc.createElement('script') as HTMLScriptElement;
            if (callback) {
                scriptElement.onload = callback;
            }
            scriptElement.async = isAsync;
            scriptElement.src = url;
            doc.getElementsByTagName('head')[0].appendChild(scriptElement);
        } else if (callback) {
            (callback as () => void)();
        }
    }

    private setGtmStart() {
        this.push({
            event: 'gtm.js',
            'gtm.start': new Date().getTime()
        });
    }

    private push(gtmDataLayerObj: any, immediate?: boolean) {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser()) {
            if (!this.gtmWindow.google_tag_manager && gtmDataLayerObj.eventCallback) {
                // Ensure callback fires even if tracking is disabled or blocked.
                gtmDataLayerObj.eventCallback();
                gtmDataLayerObj.eventCallback = undefined;
            }

            if (this.gtmWindow.dataLayer) {
                if (!immediate) {
                    this.initPromise
                        .then(() => {
                            this.gtmWindow.dataLayer.push(gtmDataLayerObj);
                        })
                        .catch(reason => reason);
                } else {
                    this.gtmWindow.dataLayer.push(gtmDataLayerObj);
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
