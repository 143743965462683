import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DataFilterService } from '@core/data-filter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-counter',
    templateUrl: './sh-counter.component.html',
})
export class ShCounterComponent implements OnInit, OnChanges, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input() count: number;
    @Input() initialValue?: string;
    public spinner = false;
    public currentStatus: string;

    constructor(private readonly dataFilter: DataFilterService, private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.dataFilter.spinnerObs.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.spinner = data.isActive;
            this.cd.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const countChange = changes['count'];
        const initialValueChange = changes['initialValue'];

        if (countChange || initialValueChange) {
            if (initialValueChange?.currentValue) {
                this.currentStatus = initialValueChange.currentValue;
            } else {
                this.currentStatus = this.count > 0 ? `We found ${this.count} results` : 'No results';
            }
            this.cd.markForCheck();
        }
    }

    ngOnDestroy() {
        this.dataFilter.spinner.next({ isActive: false, isSearch: false });
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
