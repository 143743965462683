export enum ALLOWED_EXPANSION_PANEL_TYPES {
    DEFAULT = 0,
    PRIMARY = 1,
    SECONDARY = 2,
}

export type ExpansionPanelTypes = typeof ALLOWED_EXPANSION_PANEL_TYPES[number];

export enum ALLOWED_EXPANSION_PANEL_TOGGLE_POSITION {
    RIGHT = 0,
    LEFT = 1,
}

export type ExpansionPanelTogglePosition = typeof ALLOWED_EXPANSION_PANEL_TOGGLE_POSITION[number];
