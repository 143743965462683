import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DataFilterService } from '@core/data-filter.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-input',
    templateUrl: './sh-input.component.html',
    animations: [
        trigger('spinnerEnterLeave', [
            transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
            transition(':leave', [animate('0.2s', style({ opacity: 0 }))]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShInputComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    private readonly unsubscribe = new Subject<void>();

    @Input() public formControlName: string;
    @Input() public fieldName: string;
    @Input() public value: any = '';
    @Input() public label: string;
    @Input() public disabled: boolean;
    @Input() public isReadonly: boolean;
    @Input() public customClass: string;
    @Input() public type: string;
    @Input() public name: string;
    @Input() public id: string;
    @Input() public maxLength: string;
    @Input() public max: number;
    @Input() public min: number;
    @Input() public placeholder: string;
    @Input() public focus: boolean;
    @Input() public ariaLabel: string;

    @ViewChild('input') inputElRef: ElementRef<HTMLInputElement>;

    @Output() public valueChange = new EventEmitter<string>();
    @Output() public touched = new EventEmitter<void>();

    public isDisabled: boolean;
    public isSpinnerActive = false;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private readonly _cdr: ChangeDetectorRef,
        private readonly dataFilter: DataFilterService
    ) {}

    ngOnInit() {
        this.dataFilter.spinnerObs.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.isSpinnerActive = Boolean(data.isActive && data.isSearch);
            this._cdr.markForCheck();
        });
    }

    ngAfterViewInit() {
        if (this.inputElRef && this.focus) {
            this.inputElRef.nativeElement.focus();
        }

        fromEvent(this.inputElRef.nativeElement, 'input')
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe))
            .subscribe((inputEvent: any) => {
                this.value = inputEvent.target?.value;
                this.valueChange.emit(inputEvent.target?.value);
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public onClearSearch() {
        this.value = '';
        this.valueChange.emit(this.value);
    }

    /**
     * @inheritdoc
     */
    public writeValue(value: any): void {
        this.value = value;
        if (this.max && +value > +this.max) {
            this.value = this.max;
        }

        if (this.min && value < this.min) {
            this.value = this.min;
        }
        this._cdr.markForCheck();
        this.valueChange.emit(this.value);
    }

    /**
     * @inheritdoc
     */
    public registerOnChange(value: any): void {
        this.writeValue(value);
    }

    /**
     * @inheritdoc
     */
    public registerOnTouched(): void {
        this.touched.emit();
    }

    /**
     * @inheritdoc
     */
    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
