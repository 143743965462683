import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { StorageService } from '@core/storage.service';
import { MarketingFileType } from '@models/types';
import { takeUntil } from 'rxjs/operators';
import { FilterItemType, FilterResult, FilterType } from '@models/interfaces';
import { DataFilterService } from '@core/data-filter.service';
import { sortBy } from 'lodash-es';
import { FileService } from '@core/file.service';
import { DrawerSettingType, SettingService } from '@core/setting.service';
import { VimeoPlayerComponent } from '@shared/vimeo-player/vimeo-player.component';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { PortalsService } from '@features/portals/portals.service';

export interface MarketingFileIdAndType {
    fileId?: string | null;
    link?: string | null;
    downloadLink?: string | null;
    mimeType?: string | null;
}

@Component({
  selector: 'app-marketing-files',
  template: `
    <div class="marketing-files">
        <div class="sh-filter-select__container">
            <app-sh-data-filter [data]="marketingFiles" [filters]="filters" (filtered)="setFiltered($event)"></app-sh-data-filter>
        </div>
        <div #counter>
            <app-sh-counter [count]="groupedMarketingFiles.length" [initialValue]="!isFilterActive ? 'Select a filter' : ''"></app-sh-counter>
        </div>
        <ng-container *ngIf="groupedMarketingFiles.length">
            <app-sh-panel>
                <app-sh-panel-content>
                    <app-sh-marketing-files-list
                        [data]="groupedMarketingFiles"
                        (marketingFileIdClicked)="onMarketingFileIdClicked($event)"
                        (marketingFileIdClickedDownload)="onMarketingFileIdClickedDownload($event)"
                    ></app-sh-marketing-files-list>
                </app-sh-panel-content>
            </app-sh-panel>
        </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingFilesComponent implements OnInit, OnDestroy {
    public marketingFiles: MarketingFileType[] = [];
    public groupedMarketingFiles: MarketingFileType[] = [];
    public isFilterActive: boolean;
    public filters: FilterType[] = [
        {
            title: 'Marketing File Application Area',
            operator: 'add',
            type: 'marketingFilesSelect',
            field: 'marketingFilesApplicationAreas',
            method: 'strictMarketingFiles',
            noPad: true,
            panelType: 'light',
            default: [],
            dataObserver: new BehaviorSubject<FilterType | null>(null),
        },
    ];

    public dialogConfig: MatDialogConfig = {
        panelClass: 'vimeo-player-modal',
        hasBackdrop: true,
        closeOnNavigation: true,
    };

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly store: StorageService,
        private readonly file: FileService,
        private settingService: SettingService,
        private readonly cd: ChangeDetectorRef,
        private readonly dialog: MatDialog,
        private readonly portalsService: PortalsService,
    ) {}

    public ngOnInit(): void {
        this.getMarketingFiles();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getMarketingFiles() {
        combineLatest([this.portalsService.portalObs, this.store.marketingFiles])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([portal, marketingFiles]) => {
                this.filters.forEach((filter: FilterType) => {
                    if (filter.dataObserver) {
                        filter.dataObserver.next(
                            filter.field === 'mustBe' ? DataFilterService.getMustBeFilter() : this.parseCheckboxes(filter.field, marketingFiles)
                        );
                    }
                });
                this.marketingFiles.length = 0;
                this.marketingFiles = this.marketingFiles.concat(marketingFiles.filter(m => m.portals?.includes(portal.portal)));
                this.cd.detectChanges();
        });
        this.store.getMarketingFiles();
    }

    public setFiltered(result: FilterResult): void {
        this.groupedMarketingFiles.length = 0;
        if (result.queryCount) {
            if (DataFilterService.isMarketingFileTypes(result.data)) {
                this.groupedMarketingFiles = result.data;
                this.groupedMarketingFiles = sortBy(this.groupedMarketingFiles, 'fileName');
            }
            this.isFilterActive = true;
        } else {
            this.groupedMarketingFiles = sortBy(this.marketingFiles, 'fileName');

            this.isFilterActive = false;
        }
    }

    private parseCheckboxes(field: string, data: MarketingFileType[]): FilterItemType {
        const checkboxes: FilterItemType = { field, values: [] };

        const checkboxValues: any[] = [];
        data.forEach(marketingFile => {
            const productField = (marketingFile as any)[field];
            if (productField && Array.isArray(productField)) {
                productField.forEach(item => {
                    const checkbox = {
                        label: item.displayName,
                        key: item.key,
                        checked: false,
                    };
                    checkboxValues.push(checkbox);
                });
            }
        });

        // Filter unique.
        const unique = {};
        checkboxValues.map(item => ((unique as any)[item.key] = item));
        checkboxes.values = Object.keys(unique).map(key => (unique as any)[key]);
        return checkboxes;
    }

    public onMarketingFileIdClicked(fileIdAndType: MarketingFileIdAndType): void {
        if (fileIdAndType.link && fileIdAndType.mimeType === 'video/vimeo') {
            const dialogRef = this.dialog.open(VimeoPlayerComponent, this.dialogConfig);
            dialogRef.componentInstance.videoUrl = fileIdAndType.link;
            this.cd.markForCheck();
        } else if (fileIdAndType.fileId && fileIdAndType.mimeType !== 'video/vimeo') {
            this.file.linkOrDownloadFile(fileIdAndType.fileId).then(fileLink => {
                if (fileLink) {
                    this.settingService._drawerSettingSubject.next({
                        type: DrawerSettingType.documentViewer,
                        data: fileLink,
                        state: true
                    });
                }
            });
        }
    }

    public onMarketingFileIdClickedDownload(fileIdAndType: MarketingFileIdAndType): void {
        if (fileIdAndType.fileId) {
            this.file.linkOrDownloadFile(fileIdAndType.fileId).then(fileLink => {
                if (fileLink) {
                    fileLink.click();
                }
            });
        } else if (fileIdAndType.downloadLink) {
            this.file.downloadVideo(fileIdAndType.downloadLink).click();
        }
    }
}
