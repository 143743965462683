import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { AuthenticationService } from '@core/authentication.service';
import { DataProviderService } from '@core/data-provider.service';
import { StorageService } from '@core/storage.service';
import { PortalsService } from '@features/portals/portals.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-offline-toggle',
    template: `
        <ng-container *ngIf="authenticated | async; else loginTmpl">
            <ng-container *ngIf="isOfflineAllowed">
                <div class="text__paragraph-container text__paragraph-container--slim">
                    <p>Offline mode</p>
                </div>
                <mat-slide-toggle [checked]="isOffline" (change)="onChange($event)" aria-label="Toggle offline mode" color="primary">
                    {{ isOffline ? 'On' : 'Off' }}
                </mat-slide-toggle>
            </ng-container>
        </ng-container>
        <ng-template #loginTmpl>
            <div class="text__paragraph-container text__paragraph-container--slim">
                <p>Please log in.</p>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShOfflineToggleComponent implements OnInit, OnDestroy {
    @Output() offlineChange = new EventEmitter<boolean>();

    public isOfflineAllowed = false;
    public isOffline = false;
    public authenticated = this.auth.isLoggedIn;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly storage: StorageService,
        private readonly portals: PortalsService,
        private readonly dataProvider: DataProviderService,
        private readonly cdr: ChangeDetectorRef,
        private readonly auth: AuthenticationService
    ) {}

    ngOnInit() {
        this.portals.portalObs
            .pipe(
                switchMap(currentPortal => this.storage.getSettings(currentPortal.portal)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(settings => {
                this.isOffline = Boolean(settings?.offline);
                this.cdr.markForCheck();
            });

        this.auth.accountObs.pipe(takeUntil(this.unsubscribe)).subscribe(account => {
            if (!account) {
                return;
            }

            this.isOfflineAllowed = account.hasOfflineAccess;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    async onChange(value: MatSlideToggleChange) {
        if (!this.isOfflineAllowed) {
            return;
        }

        if (value.checked) {
            if (
                !confirm(
                    `You are about to download all background files associated with "${this.portals.getCurrentPortalName()}" to use when offline. This requires a stable internet connection, and can take up to an hour first time you do this. Files will be cached and updated on your PC. Are you sure you want to proceed?`
                )
            ) {
                value.source.checked = false;
                this.cdr.markForCheck();
                return;
            }
        } else {
            if (
                !confirm(
                    `This will DELETE all your LOCAL files for the active portal "${this.portals.getCurrentPortalName()}". Files will not be available unless you have a stable internet connection. Proceed?`
                )
            ) {
                value.source.checked = true;
                this.cdr.markForCheck();
                return;
            }
        }

        this.isOffline = value.checked;
        this.offlineChange.emit(value.checked);
        await this.storage.setSettings('offline', Number(value.checked));
        this.dataProvider.fetchData();
    }
}
