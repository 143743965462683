import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DataProviderService } from '@core/data-provider.service';
import { StorageService } from '@core/storage.service';
import { formatDistanceToNow } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-status-menu',
    templateUrl: './status-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusMenuComponent implements OnInit, OnDestroy {
    public isOpen = false;
    public activeState?: string;
    public currentProgress = 0;
    private readonly unsubscribe = new Subject<void>();
    public isOffline = false;
    public offlineLastSync: string;
    public lastUpdated: string;

    constructor(private readonly dataProvider: DataProviderService, private readonly store: StorageService, private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.dataProvider
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(state => {
                this.activeState = state.state;
                if (state.message) {
                    this.currentProgress = Number(state.message);
                }
                if (state.state === 'complete') {
                    this.updateTimestamp();
                }
                this.cd.markForCheck();
            });

        this.store.getSettings().then(settings => {
            this.isOffline = Boolean(settings?.offline);
            this.cd.markForCheck();
        });

        this.updateTimestamp();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public openDropdown() {
        this.isOpen = true;
        this.updateTimestamp();
        this.cd.markForCheck();
    }

    public closeDropdown() {
        this.isOpen = false;
        this.cd.markForCheck();
    }

    public handleOfflineChange(event: boolean) {
        this.isOffline = event;
        this.cd.markForCheck();
    }

    public forceUpdate() {
        this.dataProvider.fetchData();
    }

    private async updateTimestamp(): Promise<void> {
        this.offlineLastSync = '';
        this.lastUpdated = '';
        const settings = await this.store.getSettings();
        if (settings) {
            const { lastSync } = settings;
            if (lastSync) {
                const lastTimestamp = new Date(lastSync);
                this.lastUpdated = formatDistanceToNow(lastTimestamp);
                this.offlineLastSync = `(${lastTimestamp.toLocaleString()})`;
            }
        }
        this.cd.detectChanges();
    }
}
