import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Portal } from '@models/interfaces';
import { ShSelectComponent } from '@shared/sh-select/sh-select.component';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PortalsService } from './portals.service';

@Component({
    selector: 'app-portals',
    templateUrl: 'portals.component.html',
})
export class PortalsComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @ViewChild(ShSelectComponent) private selectComponent: ShSelectComponent;
    public states = {
        initialLoad: false,
    };
    public portals: Portal[] = [];
    public currentPortal: string;

    public get currentPortalName() {
        for (const portal of this.portals) {
            if (portal.id === this.currentPortal) {
                return portal.value;
            }
        }

        return null;
    }

    constructor(private readonly portalsService: PortalsService, private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        combineLatest([this.portalsService.portals, this.portalsService.portalObs])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([portals, currentPortal]) => {
                this.portals.length = 0;
                this.portals = this.portals.concat(portals);
                this.currentPortal = currentPortal.portal;
                this.states.initialLoad = true;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public setPortal(value: string, skipNavigation = false) {
        this.portalsService.setPortal(value, skipNavigation);
    }

    public showDropdown() {
        if (this.selectComponent) {
            this.selectComponent.showDropdown();
        }
    }

    public trackByFn(index: number, item: Portal) {
        return item.id || index;
    }
}
