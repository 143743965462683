import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataFilterService } from '@core/data-filter.service';
import { StorageService } from '@core/storage.service';
import { ApplicationArea } from '@models/interfaces';
import { ShSelectComponent } from '@shared/sh-select/sh-select.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-filter-select',
    templateUrl: './sh-filter-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShFilterSelectComponent implements OnInit, OnDestroy {
    @Input() public type: string;
    @Input() public placeholderOption: string;
    @Input() public label: string;
    @Input() public ariaLabel: string;
    @Output() public valueChange = new EventEmitter<string[]>();

    @ViewChild(ShSelectComponent) private selectComponent: ShSelectComponent;

    public applicationAreas: ApplicationArea[] = [];

    private isApplicationArea = false;
    private isSubApplicationArea = false;

    private unsubscribe = new Subject<void>();

    constructor(
        private readonly store: StorageService,
        private readonly cd: ChangeDetectorRef,
        private readonly dataFilterService: DataFilterService
    ) {}

    ngOnInit(): void {
        this.isApplicationArea = this.type === 'applicationArea';
        this.isSubApplicationArea = this.type === 'subApplicationArea';

        if (this.isApplicationArea || this.isSubApplicationArea) {
            if (this.isApplicationArea) {
                this.store.applicationAreas.pipe(takeUntil(this.unsubscribe)).subscribe(applicationAreas => {
                    this.applicationAreas.length = 0;
                    this.applicationAreas = this.applicationAreas.concat(applicationAreas);
                    this.cd.markForCheck();
                });
            }

            if (this.isSubApplicationArea) {
                this.dataFilterService.currentApplicationArea.pipe(takeUntil(this.unsubscribe)).subscribe(currentApplicationArea => {
                    this.applicationAreas.length = 0;

                    if (currentApplicationArea?.subApplicationAreas?.length) {
                        this.applicationAreas = this.applicationAreas.concat(currentApplicationArea.subApplicationAreas);
                    }

                    this.cd.markForCheck();
                });
            }

            this.store.getApplicationAreas();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public showDropdown() {
        if (this.selectComponent) {
            this.selectComponent.showDropdown();
        }
    }

    public registerOnChange(currentValue: string) {
        let value = currentValue;
        if (value === 'select') {
            value = '';
        }
        if (this.isApplicationArea) {
            const currentArea = this.applicationAreas.find(x => x.name === value);
            this.dataFilterService.currentApplicationArea.next(currentArea);
        }
        this.valueChange.emit(value ? [value] : []);
    }

    public trackByFn(index: number, item: ApplicationArea) {
        return item.id;
    }
}
