import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-userguide',
    templateUrl: './userguide.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserguideComponent implements OnInit {
    private readonly pageTitle: string = 'User Guide';

    constructor(private readonly title: Title) {}

    ngOnInit(): void {
        this.title.setTitle(this.pageTitle);
    }
}
