<table class="sh-table__container" [class.sh-table__container--no-border]="isTransparentHeader">
    <thead class="sh-table__header" [class.sh-table__header--transparent]="isTransparentHeader">
        <th *ngIf="isSelectable">
            <input type="checkbox" name="selectAll" id="checkSelectAll" (change)="onSelectAll()" [disabled]="disabled" />
        </th>
        <th
            *ngFor="let column of columns"
            [ngClass]="column?.customClasses"
            (click)="onSortClick(column)"
            class="text__heading sh-table__header-item"
            [class.sh-table__header-item--disabled]="disabled || column.disableSorting"
            [class.sh-table__header-item--is-centered]="column?.center"
            [class.sh-table__header-item--small]="column?.size === 'small'"
        >
            <span>
                {{ column?.columnName }}
                <i
                    class="sh-table__header-icon"
                    *ngIf="!disabled"
                    [ngClass]="{
                        'icon-sort-asc': isOrderByDesc(column) === 'asc',
                        'icon-sort-desc': isOrderByDesc(column) === 'desc'
                    }"
                ></i>
                <i class="sh-table__header-icon"></i>
            </span>
        </th>
    </thead>
    <tbody class="sh-table__content" [class.sh-table__content--striped]="rowStriping">
        <ng-container *ngIf="tableRows?.length; else noData">
            <app-sh-table-row
                *ngFor="let data of tableRows; let idx = index; trackBy: trackRowByFn"
                [indexRow]="idx"
                [contentRow]="data"
                [columns]="columns"
                [templates]="templates"
                class="sh-table__row sh-table__content-row"
                (linkClicked)="onLinkClicked($event)"
                (marketingFileIdClicked)="onMarketingFileIdClicked($event)"
                (marketingFileIdClickedDownload)="onMarketingFileIdClickedDownload($event)"
            >
                <ng-content select="ng-template"></ng-content>
            </app-sh-table-row>
        </ng-container>

        <ng-template #noData>
            <tr class="sh-table__content-row">
                <td class="sh-table__content-item sh-table__content--no-content" colspan="100%">There is no data to display</td>
                <!-- need add translation -->
            </tr>
        </ng-template>
    </tbody>
    <tfoot>
        <tr>
            <td colspan="100%">
                <app-sh-table-paginator
                    *ngIf="usingPaginator"
                    [disabled]="disabled"
                    [hidePageSize]="hidePageSize"
                    [length]="totalPages"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [showFirstLastButtons]="showFirstLastButtons"
                    (pageChange)="onPageChange($event)"
                    (pageSizeChange)="onPageSizeChange($event)"
                ></app-sh-table-paginator>
            </td>
        </tr>
    </tfoot>
</table>
