import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiInterceptor } from '@core/api.interceptor';
import { TranslationLoaderService } from '@core/translation-loader.service';
import { FrontpageComponent } from '@features/frontpage/frontpage.component';
import { LanguageSelectorComponent } from '@features/language-selector/language-selector.component';
import { MainNavigationComponent } from '@features/main-navigation/main-navigation.component';
import { NotificationComponent } from '@features/notification/notification.component';
import { PortalsComponent } from '@features/portals/portals.component';
import { ProductCatalogueComponent } from '@features/product-catalogue/product-catalogue.component';
import { ProductPageComponent } from '@features/product-page/product-page.component';
import { SolutionFinderComponent } from '@features/solution-finder/solution-finder.component';
import { MarketingFilesComponent } from '@features/marketing-files/marketing-files.component';
import { StatusMenuDropdownComponent } from '@features/status-menu/status-menu-dropdown.component';
import { StatusMenuComponent } from '@features/status-menu/status-menu.component';
import { TopBarComponent } from '@features/top-bar/top-bar.component';
import { UserComponent } from '@features/user/user.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';

import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserguideComponent } from '@features/userguide/userguide.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
    declarations: [
        AppComponent,
        LanguageSelectorComponent,
        TopBarComponent,
        NotificationComponent,
        MainNavigationComponent,
        ProductCatalogueComponent,
        SolutionFinderComponent,
        UserComponent,
        ProductPageComponent,
        FrontpageComponent,
        UserguideComponent,
        PortalsComponent,
        StatusMenuComponent,
        StatusMenuDropdownComponent,
        MarketingFilesComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        HttpClientModule,
        InlineSVGModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoaderService,
            },
        }),
        FormsModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        SharedModule,
        PdfViewerModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
})
export class AppModule {}
