import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuLink, NavigationService } from '@core/navigation.service';
import { PortalsService } from '@features/portals/portals.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-main-navigation',
    templateUrl: './main-navigation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    menuLinks: MenuLink[] = this.navigationService.menuLinks;

    constructor(
        private readonly portalsService: PortalsService,
        private readonly router: Router,
        public readonly navigationService: NavigationService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        this.portalsService.portalObs.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            if (!value.skipNavigation) {
                this.menuLinks.forEach(menuLink => {
                    if (menuLink.path !== '/' && this.router.url.includes(menuLink.path)) {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([menuLink.path]));
                    }
                });
            }
        });
    }
}
