import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { DataProviderService } from '@core/data-provider.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-status-menu-dropdown',
    templateUrl: './status-menu-dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusMenuDropdownComponent implements OnInit, OnDestroy {
    @Input() statusIcons: TemplateRef<any>;
    @Input() activeState: string;
    @Input() currentProgress = 0;
    @Output() closeEvent = new EventEmitter<void>();
    @Output() offlineChange = new EventEmitter<boolean>();

    @HostBinding('attr.role')
    public role = 'dialog';

    @HostBinding('attr.aria-label')
    public ariaLabel = 'Options to control synchronization';

    @HostBinding('attr.aria-live')
    public ariaLive = 'assertive';

    public logs: string[] = [];
    public isOffline: boolean;
    private readonly unsubscribe = new Subject<void>();
    private clickCount = 0;

    constructor(private readonly dataProvider: DataProviderService, private readonly cd: ChangeDetectorRef, private eRef: ElementRef) {}

    ngOnInit(): void {
        this.dataProvider
            .getReportQueue()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(logs => {
                logs.reverse();
                this.logs.unshift(...logs);
                if (this.logs.length > 100) {
                    this.logs = this.logs.slice(0, 99);
                }
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    @HostListener('window:click', ['$event']) public closeDropdown(event: MouseEvent) {
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0) {
            this.closeEvent.emit();
        }
        this.clickCount++;
    }

    handleOfflineChange(event: boolean) {
        this.offlineChange.emit(event);
    }
}
