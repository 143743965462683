import { Injectable } from '@angular/core';
import { StoredFile } from '@models/interfaces';

import { StorageService } from './storage.service';

export const FILE_URL = '/api/v1/files';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(private readonly store: StorageService) {}

    /**
     * Show a file as an iframe in a new tab
     *
     * Note: Very experimental
     */
    public showFile(file: StoredFile): void {
        const dataUri = `data:${file.mimeType};base64,${file.content}`;
        const w = window.open('about:blank');

        setTimeout(() => {
            // FireFox seems to require a setTimeout for this to work
            if (!w) {
                return;
            }

            const frame = w.document.createElement('iframe');
            frame.style.width = '100%';
            frame.style.height = '100%';
            frame.src = dataUri;

            w.document.title = file.fileName;
            w.document.body.appendChild(frame);
        }, 0);
    }

    /**
     * Makes a decision based on a database lookup if the file should be shown
     * "online" or "offline"
     *
     * @param fileId The id of the file
     */
    public async linkOrDownloadFile(fileId: string) {
        const storedFile = await this.store.getFileById(fileId);

        if (!storedFile) {
            return this.linkToFile(fileId);
        }

        return this.downloadFile(storedFile);
    }

    /**
     * Downloads a given base64 file
     *
     * It works by creating a document a-tag with a download attribute and click it
     *
     * @param file An object containing the stored file
     */
    public downloadFile(file: StoredFile): HTMLAnchorElement | undefined {
        try {
            const contentBlob = new Blob([file.content], {
                type: `${file.mimeType};charset=utf-8`,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(contentBlob);
            link.download = file.fileName;
            link.target = '_blank';
            return link;
        } catch (err: any) {
            console.error('Could not show stored file', err.message);
        }
    }

    /**
     * Link directly to online file
     *
     * @param fileId The id of the file on the server
     */
    public linkToFile(fileId: string): HTMLAnchorElement {
        const link = document.createElement('a');
        link.href = `/api/v1/files?id=${fileId}`;
        link.target = '_blank';
        link.download = 'Document.pdf';
        return link;
    }

    public downloadVideo(videoUrl: string): HTMLAnchorElement {
        const link = document.createElement('a');
        link.href = videoUrl;
        return link;
    }
}
