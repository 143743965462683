import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { FILE_URL, FileService } from '@core/file.service';
import { StorageService } from '@core/storage.service';
import { PortalsService } from '@features/portals/portals.service';
import { ProductProfileViewModel } from '@models/api';
import { TabDataType, TableLinkType, TabType } from '@models/interfaces';
import { ProductType } from '@models/types';
import { TableModel } from '@shared/sh-table/table.model';
import { map, sortBy } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DrawerSettingType, SettingService } from '@core/setting.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

interface ProductDataSheetTableModel {
    icons?: Icon[];
    id: string;
    packaging: string;
    pallet: string;
    form?: string;
    quantityPerUnit: string;
    weight: string;
    language: TableLinkType[];
    revised?: string;
}

interface ProductCertificateTableModel {
    icons: Icon[];
    description: TableLinkType[];
    id: string;
}

interface ProductRecipeTableModel {
    icons: Icon[];
    description: TableLinkType[];
    applicationArea: string | string[];
    subApplicationArea: string | string[];
}

interface Icon {
    src: string;
    title?: string;
}

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPageComponent implements OnInit, OnDestroy {
    public filteredProductFiles: TableLinkType[] = [];
    public productProfile?: ProductProfileViewModel;
    public data?: ProductType;
    public currentPortal?: string;
    public activeTab?: TabDataType;
    public selectedProductProfilePdfLink: TableLinkType;
    public fileLink: HTMLAnchorElement | undefined;
    public isLoadingPdf?: boolean;

    public tabData: TabType[];

    public productDataSheet: TableModel<ProductDataSheetTableModel> = {
        headerConfig: [
            { columnName: 'Form', fieldID: 'form' },
            { columnName: 'Kg/unit', fieldID: 'quantityPerUnit' },
            { columnName: 'Packaging', fieldID: 'packaging' },
            { columnName: 'Pallet', fieldID: 'pallet' },
            { columnName: 'Weight', fieldID: 'weight' },
            { columnName: 'ID', fieldID: 'id', center: true },
            { columnName: 'Language', fieldID: 'language', isLinkColumn: true },
        ],
        rowData: [],
    };

    public productMaterialDataSheet: TableModel<ProductDataSheetTableModel> = {
        headerConfig: [
            { columnName: 'Form', fieldID: 'form' },
            { columnName: 'Kg/unit', fieldID: 'quantityPerUnit' },
            { columnName: 'Packaging', fieldID: 'packaging' },
            { columnName: 'Pallet', fieldID: 'pallet' },
            { columnName: 'Weight', fieldID: 'weight' },
            { columnName: 'ID', fieldID: 'id', center: true },
            { columnName: 'Language', fieldID: 'language', isLinkColumn: true },
        ],
        rowData: [],
    };

    public productRecipes: TableModel<ProductRecipeTableModel> = {
        headerConfig: [
            {
                columnName: '',
                fieldID: 'icons',
                templateRefName: 'iconTpl',
                size: 'small',
            },
            { columnName: 'Title', fieldID: 'description', isLinkColumn: true },
            { columnName: 'Application Area', fieldID: 'applicationArea' },
            { columnName: 'Sub Application Area', fieldID: 'subApplicationArea' },
        ],
        rowData: [],
    };

    public productCertificate: TableModel<ProductCertificateTableModel> = {
        headerConfig: [
            {
                columnName: '',
                fieldID: 'icons',
                templateRefName: 'iconTpl',
                size: 'small',
            },
            { columnName: 'Certificate', fieldID: 'description', isLinkColumn: true },
            { columnName: 'ID', fieldID: 'id' },
        ],
        idField: 'id',
        rowData: [],
    };
    public dialogData: { id: string };

    private readonly unsubscribe = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) _dialogData: { id: string },
        private readonly title: Title,
        private readonly store: StorageService,
        private readonly file: FileService,
        private readonly cdr: ChangeDetectorRef,
        private readonly portalsService: PortalsService,
        private readonly dialog: MatDialog,
        private readonly viewContainerRef: ViewContainerRef,
        private settingService: SettingService
    ) {
        this.dialogData = _dialogData;
    }

    ngOnInit() {
        this._getProduct(this.dialogData.id);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public async onTabData(tabData: TabDataType) {
        this.activeTab = tabData;

        // Scroll to the top of the dialog container
        const dialogContainerEl = document.getElementsByClassName('mat-dialog-container')[0];

        if (!dialogContainerEl) {
            return;
        }

        const hostElement = this.viewContainerRef.element.nativeElement;
        dialogContainerEl.scrollTop = hostElement.offsetTop;
        this.cdr.markForCheck();
    }

    public onLinkClicked(link: TableLinkType, event?: MouseEvent) {
        if (link.params?.fileId) {
            this.file.linkOrDownloadFile(link.params.fileId).then(fileLink => {
                if (fileLink) {
                    this.settingService._drawerSettingSubject.next({
                        type: DrawerSettingType.documentViewer,
                        data: fileLink,
                        state: true
                    });
                }
            });
        }

        if (event && link.externalLink) {
            event.preventDefault();
            return false;
        }
    }

    public generateProductProfilePdfLink(link: TableLinkType) {
        this.selectedProductProfilePdfLink = link;
        if (link.params?.fileId) {
            this.isLoadingPdf = true;
            this.file.linkOrDownloadFile(link.params.fileId).then(fileLink => {
                if (fileLink) {
                    this.fileLink = fileLink;
                    this.cdr.markForCheck();
                }
            });
        }
    }

    public setProductProfileLanguage(value: string) {
        if (!this.selectedProductProfilePdfLink || (this.selectedProductProfilePdfLink.label !== value)) {
            const selectedLanguage = this.filteredProductFiles.find(file => file.label === value);
            if (selectedLanguage) {
                this.generateProductProfilePdfLink(selectedLanguage);
            }
        }
    }

    public trackByFn(index: number, item: TableLinkType) {
        return item.params?.fileId || index;
    }

    public closeDialog() {
        this.dialog.closeAll();
    }

    public sortLanguages(languages: TableLinkType[]): TableLinkType[] {
        // First sort alphabetically
        const sorted = sortBy(languages, 'label');
        sorted.forEach(language => {
            // Then move 'EN' language to the beginning of the array
            if (language.label === 'EN') {
                sorted.unshift(sorted.splice(sorted.indexOf(language), 1)[0]);
            }
        });
        return sorted;
    }

    public downloadFile() {
        if (this.fileLink) {
            this.fileLink.click();
        }
    }

    public loadComplete(pdf: PDFDocumentProxy) {
        if (pdf) {
            this.isLoadingPdf = false;
        }
    }

    private async _getProduct(id: string) {
        const product = await this.store.getProductById(id);

        if (!product) {
            this.title.setTitle(`Product ${id} not found`);
            this.cdr.markForCheck();
            return;
        }

        this.data = product;
        this.title.setTitle(product.name || '');
        this.cdr.markForCheck();

        this.portalsService.portalObs.pipe(takeUntil(this.unsubscribe)).subscribe(portal => {
            this.currentPortal = portal.portal;
            this.productProfile = product.productProfiles ? product.productProfiles[this.currentPortal] : undefined;

            this.filteredProductFiles.length = 0;

            if (this.productProfile?.productProfileFiles?.length) {
                this.productProfile.productProfileFiles?.forEach((profile, i) => {
                    this.filteredProductFiles.push({
                        label: profile?.language || '-',
                        route: `${FILE_URL}?id=${profile.fileId}`,
                        params: { fileId: profile.fileId },
                        externalLink: true,
                    });
                });

                this.generateProductProfilePdfLink(this.sortLanguages(this.filteredProductFiles)[0]);
            }
        });

        // Combine weight and unit into weight field.
        product.variants?.map(variant => {
            const { weight, unit } = variant;
            variant.weight = [weight || 0, unit].join(' ') as unknown as number;
            return variant;
        });

        const productDataSheetRowData: ProductDataSheetTableModel[] = [];
        const productMaterialDataSheetRowData: ProductDataSheetTableModel[] = [];

        // Sort variants by portal
        this.data.variants = this.data.variants?.filter(variant => variant.portal === this.currentPortal);

        (this.data.variants || []).forEach(variant => {
            const variantIcons: Icon[] = [
                {
                    src: '/assets/icons/document.svg',
                    title: 'Document',
                },
            ];

            if (variant.isDifferentFromProduct) {
                variantIcons.push({
                    src: '/assets/icons/arrowDown.svg',
                    title: 'Warning: Variant has different properties from family',
                });
            }

            const dataSheetLanguages: TableLinkType[] = [];

            (variant.dataSheet || []).forEach(dataSheet => {
                dataSheetLanguages.push({
                    label: dataSheet.language || '-',
                    route: `${FILE_URL}?id=${dataSheet.fileId}`,
                    params: { fileId: dataSheet.fileId },
                    externalLink: true,
                });
            });

            productDataSheetRowData.push({
                icons: variantIcons,
                id: variant.id || '',
                form: variant.form || '',
                packaging: variant.packaging || '',
                language: this.sortLanguages(dataSheetLanguages) || [],
                pallet: variant.pallet || '',
                weight: variant.weight?.toString() || '',
                quantityPerUnit: variant.quantityPerUnit?.toString() || '',
            });

            const materialDataSheetLanguages: TableLinkType[] = [];

            (variant.materialSafetyDataSheet || []).forEach(dataSheet => {
                materialDataSheetLanguages.push({
                    label: dataSheet.language || '-',
                    route: `${FILE_URL}?id=${dataSheet.fileId}`,
                    params: { fileId: dataSheet.fileId },
                    externalLink: true,
                });
            });

            productMaterialDataSheetRowData.push({
                icons: variantIcons,
                id: variant.id || '-',
                quantityPerUnit: variant.quantityPerUnit?.toString() || '',
                form: variant.form || '',
                packaging: variant.packaging || '',
                language: this.sortLanguages(materialDataSheetLanguages) || [],
                pallet: variant.pallet || '',
                weight: variant.weight?.toString() || '',
            });
        });

        this.productDataSheet.rowData = productDataSheetRowData;
        this.productMaterialDataSheet.rowData = productMaterialDataSheetRowData;

        // Sort certificate id's by current portal variants
        const currentVariantIds = map(this.data.variants, 'id');
        this.data.certificates?.map(certificate => {
            return (certificate.variantIds = certificate.variantIds?.filter(variantId => currentVariantIds.includes(variantId)));
        });

        const icons: Icon[] = [{ src: '/assets/icons/document.svg', title: 'Document' }];

        const productCertificateRowData: ProductCertificateTableModel[] = [];
        (this.data.certificates || []).forEach(certificate => {
            if (certificate.variantIds?.length) {
                productCertificateRowData.push({
                    icons,
                    description: [
                        {
                            label: certificate.description || '',
                            route: `${FILE_URL}?id=${certificate.fileId}`,
                            params: { fileId: certificate.fileId },
                            externalLink: true,
                        },
                    ],
                    id: certificate.variantIds?.join(', ') || '-',
                });
            }
        });

        this.productCertificate.rowData = productCertificateRowData;

        const productRecipeRowData: ProductRecipeTableModel[] = [];
        await (async () => {
            await Promise.all(
                (this.data?.recipes || []).map(async recipeId => {
                    const recipe = await this.store.getRecipeById(recipeId).catch(() => undefined);

                    if (!recipe || !recipe.recipeFile) {
                        return;
                    }

                    if (recipe.portals?.includes(this.currentPortal || '')) {
                        productRecipeRowData.push({
                            icons,
                            description: [
                                {
                                    label: recipe.recipeFile.fileName || '-',
                                    route: `${FILE_URL}?id=${recipe.recipeFile.fileId}`,
                                    params: { fileId: recipe.recipeFile.fileId },
                                    externalLink: true,
                                },
                            ],
                            applicationArea: recipe.applicationArea?.join(', ') || '-',
                            subApplicationArea: recipe.subApplicationArea?.join(', ') || '-',
                        });
                    }
                })
            );
            this.productRecipes.rowData = sortBy(productRecipeRowData, 'description[0].label');
            this.cdr.markForCheck();
        })();

        this.tabData = [
            {
                id: 'datasheet',
                name: 'Data sheet',
                data: {
                    component: 'table',
                    tabData: this.productDataSheet,
                },
            },
            {
                id: 'materialdatasheet',
                name: 'MSDS',
                data: {
                    component: 'table',
                    tabData: this.productMaterialDataSheet,
                },
            },
            {
                id: 'details',
                name: 'Product profile',
                data: {
                    component: 'productProfile',
                },
            },
            {
                id: 'recipes',
                name: 'Recipes',
                data: {
                    component: 'table',
                    tabData: this.productRecipes,
                },
            },
            {
                id: 'certificates',
                name: 'Certificates',
                data: {
                    component: 'table',
                    tabData: this.productCertificate,
                },
            },
        ];
    }
}
