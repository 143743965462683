import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationInsightsService } from '@core/application-insights.service';
import { AuthenticationService } from '@core/authentication.service';
import { UpdateService } from '@core/update.service';
import { ProductPageComponent } from '@features/product-page/product-page.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DrawerSettingModel, SettingService } from '@core/setting.service';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
import { GtmTrackingService } from '@core/gtm-tracking.service';

@Component({
    selector: 'app-root',
    template: `
        <mat-drawer-container [hasBackdrop]="true">
            <mat-drawer
                #documentViewerDrawer
                class="sh-mat-drawer"
                [mode]="'over'"
                [position]="'end'"
                (closedStart)="drawerClosedStart()"
            >
                <app-sh-document-viewer [drawerSetting]="drawerSetting" (closeDocumentViewerEvent)="closeDocumentViewer()"></app-sh-document-viewer>
            </mat-drawer>
            <mat-drawer-content>
                <div class="app-container">
                    <app-top-bar></app-top-bar>
                    <app-notification></app-notification>
                    <div class="layout">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    @ViewChild('documentViewerDrawer') documentViewerDrawer: MatSidenav;
    public drawerSetting?: DrawerSettingModel;
    public dialogConfig: MatDialogConfig = {
        width: '90%',
        height: '700px',
        maxWidth: '900px',
        hasBackdrop: true,
        closeOnNavigation: true,
    };
    constructor(
        private readonly aiService: ApplicationInsightsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthenticationService,
        private readonly update: UpdateService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly cd: ChangeDetectorRef,
        private settingService: SettingService,
        @Inject(DOCUMENT) private document: Document,
        private gtmTrackingService: GtmTrackingService
    ) {
        this.setGtmId('GTM-5X5XVVH');
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        this.dialogInit();
        this.drawerInit();

        this.update.checkForUpdate();

        this.aiService.startApplicationInsights();

        const defaultLang = 'en';
        this.translate.setDefaultLang(defaultLang);

        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', defaultLang);
        }

        this.translate.use(localStorage.getItem('lang') || defaultLang);

        this.auth.check();
    }

    dialogInit() {
        this.route.queryParamMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            const id = params.get('productId');
            if (id) {
                this.dialogConfig.data = { id };
                this.dialog
                    .open(ProductPageComponent, this.dialogConfig)
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.router.navigate([], { queryParams: {} });
                    });
                this.cd.markForCheck();
            }
        });
    }

    drawerInit() {
        this.settingService.drawerSetting$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(drawerSetting => {
                if (drawerSetting.state) {
                    this.dialog.closeAll();
                    this.document.body.classList.add('cdk-global-scrollblock');
                    this.drawerSetting = drawerSetting;
                    this.documentViewerDrawer.open();
                    this.cd.markForCheck();
                }
            });
    }

    drawerClosedStart() {
        this.document.body.classList.remove('cdk-global-scrollblock');
        this.settingService._drawerSettingSubject.next({
            state: false
        });
        this.settingService._loadingSubject.next(false);
    }

    closeDocumentViewer(): void {
        if (this.documentViewerDrawer) {
            this.documentViewerDrawer.close();
        }
    }

    private setGtmId(gtmId: string): void {
        this.gtmTrackingService.initGtm(gtmId);
    }
}
