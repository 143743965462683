<div class="sh-counter__container">
    <div *ngIf="spinner; else status" class="sh-counter__is-searching">
        <span class="dot dot-one">.</span>
        <span class="dot dot-two">.</span>
        <span class="dot dot-three">.</span>
    </div>

    <ng-template #status>
        <h1 class="sh-counter">
            {{ currentStatus }}
        </h1>
    </ng-template>
</div>
