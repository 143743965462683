<nav class="main-navigation">
    <h1 class="element-hidden">Main navigation</h1>
    <ul class="main-navigation__list">
        <li class="main-navigation__list-item" *ngFor="let menuLink of menuLinks">
            <a
                class="menu-link text__heading"
                [routerLinkActive]="['menu-link--active']"
                [routerLink]="[menuLink.path]"
                [routerLinkActiveOptions]="{ exact: menuLink.exact }"
                [class.menu-link--dimmed]="menuLink.dimmed === true"
                >{{ menuLink.title }}</a
            >
        </li>
    </ul>
</nav>
