<td
    *ngFor="let column of columns; trackBy: trackByFunction"
    class="sh-table__content-item"
    [class.sh-table__content-item--link-column]="column?.isLinkColumn"
    [class.sh-table__content-item--appl-area-column]="column?.isApplicationAreaColumn"
>
    <ng-container *ngIf="column?.templateRefName; else noTemplate">
        <ng-container *ngTemplateOutlet="templates[column?.templateRefName]; context: { row: contentRow[column?.fieldID] }"> </ng-container>
    </ng-container>

    <ng-template #noTemplate>
        <ng-container *ngIf="!column?.isLinkColumn; else linkTemplate">
            <ng-container *ngIf="column?.fieldID !== 'applicationAreas'">
                {{ sanitize(contentRow[column?.fieldID]) }}
            </ng-container>
            <ng-container *ngIf="column?.fieldID === 'applicationAreas'">
                <p *ngFor="let aA of contentRow[column?.fieldID]">
                    {{ sanitize(aA) }}
                </p>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #linkTemplate>
        <ng-container *ngIf="column?.fieldID !== 'marketingFileId'; else marketingFileLinkTemplate">
            <ng-container *ngFor="let link of contentRow[column?.fieldID]; let last = last">
                <a
                    *ngIf="link.externalLink"
                    class="sh-button__link sh-button__link--has-partition"
                    (click)="onLinkClicked($event, link)"
                >
                    {{ link.label }}
                </a>
                <a
                    *ngIf="!link.externalLink"
                    class="sh-button__link sh-button__link--has-partition"
                    [routerLink]="link.route"
                    [queryParams]="link.routeParams"
                    [skipLocationChange]="link.skipLocationChange || false"
                    (click)="onLinkClicked($event, link)"
                >
                    {{ link.label }}
                </a>
                <span *ngIf="!last">/</span>
            </ng-container>
        </ng-container>
        <ng-template #marketingFileLinkTemplate>
            <ng-container *ngIf="contentRow[column?.fieldID]['mimeType'] !== 'video/vimeo'">
                <div class="marketing-files__file-links">
                    <a
                        title="View Document"
                        *ngIf="contentRow[column?.fieldID]"
                        class="sh-button__link sh-button__link--has-partition marketing-files__file-link"
                        (click)="onMarketingFileIdClicked($event, contentRow[column?.fieldID])"
                    >
                        <span class="marketing-files__file-link-text">
                            <span class="marketing-files__file-link-text-icon" [inlineSVG]="'./assets/icons/file-preview.svg'" aria-hidden="true"></span>
                        </span>
                    </a>
                    <a
                        title="Download Document"
                        *ngIf="contentRow[column?.fieldID]"
                        class="sh-button__link sh-button__link--has-partition marketing-files__file-link"
                        (click)="onMarketingFileIdClickedDownload($event, contentRow[column?.fieldID])"
                    >
                        <span class="marketing-files__file-link-text">
                            <span class="marketing-files__file-link-text-icon" [inlineSVG]="'./assets/icons/download-arrow.svg'" aria-hidden="true"></span>
                        </span>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngIf="contentRow[column?.fieldID]['mimeType'] === 'video/vimeo'">
                <div class="marketing-files__file-links">
                    <a
                        title="Play Video"
                        *ngIf="contentRow[column?.fieldID]"
                        class="sh-button__link sh-button__link--has-partition marketing-files__file-link"
                        (click)="onMarketingFileIdClicked($event, contentRow[column?.fieldID])"
                    >
                        <span class="marketing-files__file-link-icon" [inlineSVG]="'./assets/icons/play.svg'" aria-hidden="true"></span>
                    </a>
                    <a
                        title="Download Video"
                        *ngIf="contentRow[column?.fieldID]"
                        class="sh-button__link sh-button__link--has-partition marketing-files__file-link"
                        (click)="onMarketingFileIdClickedDownload($event, contentRow[column?.fieldID])"
                    >
                        <span class="marketing-files__file-link-text">
                            <span class="marketing-files__file-link-text-icon" [inlineSVG]="'./assets/icons/download-arrow.svg'" aria-hidden="true"></span>
                        </span>
                    </a>
                </div>
            </ng-container>
        </ng-template>
    </ng-template>
</td>
