import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-sh-expansion-panel-header',
    template: '<ng-content></ng-content>',
})
export class ShExpansionPanelHeaderComponent {
    @HostBinding('attr.aria-hidden')
    ariaHidden = true;
}
