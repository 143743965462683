import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { ALLOWED_EXPANSION_PANEL_TYPES, ExpansionPanelTogglePosition, ExpansionPanelTypes } from './sh-expansion-panel.model';

@Component({
    selector: 'app-sh-expansion-panel',
    templateUrl: './sh-expansion-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShExpansionPanelComponent {
    private _type: ExpansionPanelTypes = 'default';

    @Input() disabled: boolean;
    @Input() expanded = false;
    @Input() hideToggle = false;
    @Input() togglePosition: ExpansionPanelTogglePosition = 'right';
    @Input() ariaLabel: string;
    @Input() public set type(t: ExpansionPanelTypes) {
        t = t || 'default';
        if (!this.hasAllowedValue(t, ALLOWED_EXPANSION_PANEL_TYPES, 'ALLOWED_EXPANSION_PANEL_TYPES')) {
            return;
        }
        this._type = t;
        if (this._type !== t) {
            this.cdr.markForCheck();
        }
    }

    public get type(): ExpansionPanelTypes {
        return this._type.toLocaleLowerCase();
    }

    @Output() closed: EventEmitter<void> = new EventEmitter();
    @Output() opened: EventEmitter<void> = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {}

    public headerClick(): void {
        if (!this.disabled) {
            this.expanded = !this.expanded;
            this.cdr.markForCheck();
        }
    }

    private hasAllowedValue(value: any, collection: any, collectionName: string): boolean {
        let hasAllowedType = false;
        for (const type in collection) {
            if (value === type.toLowerCase()) {
                hasAllowedType = true;
                break;
            }
        }
        if (!hasAllowedType) {
            console.warn(`The value '${value}' does not belong to the collection ${collectionName}`);
            return false;
        }
        return true;
    }
}
