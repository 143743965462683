import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum DrawerSettingType {
    documentViewer = 'DOCUMENT_VIEWER',
}

export type DrawerSettingData = HTMLAnchorElement;

export type DrawerSettingModel = {
    state: boolean;
    type?: DrawerSettingType;
    data?: DrawerSettingData;
};

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    _drawerSettingSubject = new Subject<DrawerSettingModel>();
    drawerSetting$ = this._drawerSettingSubject.asObservable();
    _loadingSubject = new Subject<boolean>();
    loading$ = this._loadingSubject.asObservable();
}
