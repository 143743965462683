<div *ngIf="authenticated$ | async; else login" class="user" role="button" aria-label="Show the user menu" (click)="showDropdown()">
    <div class="user__button" role="button" (click)="handleMenu()" (blur)="handleMenu($event)" aria-label="Toggle the user menu" tabindex="0">
        <ng-container *ngIf="gravatarUrl; else empty">
            <img
                class="user__image"
                [src]="gravatarUrl"
                [srcset]="gravatarUrl + ' 1x,' + gravatarUrlRetina + ' 2x'"
                width="32"
                height="32"
                loading="lazy"
                alt="avatar"
                [attr.aria-label]="'The avatar of ' + (user?.name || 'the user')"
            />
        </ng-container>
        <ng-template #empty>
            <div class="user__image--empty" aria-hidden="true"></div>
        </ng-template>
        <div *ngIf="user?.name" class="user__name">
            <p aria-label="The name of the logged in user">{{ user?.name }}</p>
        </div>
        <div class="user__menu">
            <app-sh-select (changeEvent)="handleLogout()" [ariaLabel]="'Options for the user'">
                <app-sh-select-option value="Log out"></app-sh-select-option>
            </app-sh-select>
        </div>
        <i aria-hidden="true" class="sh-select__dropdown-arrow icon-arrow-down"></i>
    </div>
</div>
<ng-template #login>
    <div class="user">
        <button class="user__button" shButton color="white" (click)="handleLogin()" aria-label="Click this button to log in" aria-live="polite">
            <p>Log in</p>
        </button>
    </div>
</ng-template>
