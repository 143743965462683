<div
    class="status-menu top-bar__item status-menu__status-bar-container"
    [ngClass]="{
        'status-menu__status-bar-container--dark': !isOpen && activeState === 'inprog',
        'status-menu__status-bar--loading': !isOpen && activeState === 'connecting'
    }"
    (click)="openDropdown()"
    role="button"
    aria-label="Open the synchronization menu"
>
    <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
    <div
        *ngIf="!isOpen && activeState === 'inprog'"
        aria-hidden="true"
        class="status-menu__status-bar status-menu__status-bar--loading"
        [style.transform]="'translate3d(' + currentProgress + '%, 0, 0)'"
    ></div>
</div>

<app-status-menu-dropdown
    *ngIf="isOpen"
    [statusIcons]="statusIcons"
    [activeState]="activeState"
    [currentProgress]="currentProgress"
    (offlineChange)="handleOfflineChange($event)"
    (closeEvent)="closeDropdown()"
></app-status-menu-dropdown>

<ng-template #statusIcons let-secondary="secondary">
    <div class="status-menu__icon-container" [ngSwitch]="activeState" aria-label="Indicating the current status of synchronization">
        <ng-container *ngSwitchCase="'complete'">
            <ng-container *ngIf="isOffline; else isOnline">
                <span
                    *ngIf="!secondary; else updateMessage"
                    inlineSVG="assets/icons/status-complete-offline.svg"
                    class="status-menu__icon"
                    aria-hidden="true"
                ></span>
                <ng-template #updateMessage>
                    <div class="status-menu__message-container">
                        <div class="status-menu__message-container status-menu__message-container--column">
                            <span inlineSVG="assets/icons/status-complete-secondary.svg" class="status-menu__icon" aria-hidden="true"></span>
                            <p class="status-menu-dropdown__status-bar-message status-menu-dropdown__status-bar-message--bold">
                                Updated {{ lastUpdated }} ago
                            </p>
                            <p class="status-menu-dropdown__status-bar-message" *ngIf="offlineLastSync">
                                {{ offlineLastSync }}
                            </p>
                        </div>
                        <button (click)="forceUpdate()" class="status-menu__message-button">
                            <span class="status-menu__icon--update" inlineSVG="assets/icons/update.svg" aria-hidden="true"></span>
                            <span>Update Now</span>
                        </button>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #isOnline>
                <span
                    inlineSVG="assets/icons/status-complete{{ secondary ? '-secondary' : '' }}.svg"
                    class="status-menu__icon"
                    aria-hidden="true"
                ></span>

                <span class="status-menu-dropdown__status-bar-message" *ngIf="secondary"> Online and updated </span>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'connecting'">
            <span
                inlineSVG="assets/icons/status-connecting{{ secondary ? '-secondary' : '' }}.svg"
                class="status-menu__icon"
                aria-hidden="true"
            ></span>
            <span class="status-menu-dropdown__status-bar-message" *ngIf="secondary">Connecting</span>
        </ng-container>
        <ng-container *ngSwitchCase="'inprog'">
            <span
                inlineSVG="assets/icons/status-in-progress{{ secondary ? '-secondary' : '' }}.svg"
                class="status-menu__icon"
                aria-hidden="true"
            ></span>
            <span class="status-menu-dropdown__status-bar-message" *ngIf="secondary">Updating products and files</span>
        </ng-container>
        <!-- To do: how do we detect if the user has internet connection? -->

        <ng-container *ngSwitchCase="'error'">
            <span inlineSVG="assets/icons/status-error{{ secondary ? '-secondary' : '' }}.svg" class="status-menu__icon" aria-hidden="true"></span>
            <p class="status-menu-dropdown__status-bar-message--bold" *ngIf="secondary">Warning</p>
            <span class="status-menu-dropdown__status-bar-message" *ngIf="secondary">No server connection</span>
        </ng-container>
        <ng-container *ngSwitchCase="'deviceoutofdiskspace'">
            <span inlineSVG="assets/icons/status-error{{ secondary ? '-secondary' : '' }}.svg" class="status-menu__icon" aria-hidden="true"></span>
            <p class="status-menu-dropdown__status-bar-message--bold" *ngIf="secondary">Warning</p>
            <span class="status-menu-dropdown__status-bar-message" *ngIf="secondary"> The device is out of disk space </span>
        </ng-container>
    </div>
</ng-template>
