import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '@core/application-insights.service';
import { DataFilterService } from '@core/data-filter.service';
import { StorageService } from '@core/storage.service';
import { PortalsService } from '@features/portals/portals.service';
import { FilterResult, FilterType } from '@models/interfaces';
import { ProductType } from '@models/types';
import { combineLatest, of, ReplaySubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-product-catalogue',
    templateUrl: './product-catalogue.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCatalogueComponent implements OnInit, OnDestroy {
    public products: ProductType[] = [];
    public filtered: ProductType[] = [];
    private productsSubject = new ReplaySubject<ProductType[]>(1);
    private readonly unsubscribe = new Subject<void>();
    public topFilters: FilterType[] = [
        {
            title: 'Application Area',
            operator: 'add',
            type: 'select',
            field: 'applicationArea',
            method: 'strict',
            placeholder: 'All',
            noPad: true,
            panelType: 'light',
            default: [],
        },
        {
            title: 'Search',
            operator: 'add',
            type: 'text',
            field: '__indexValue',
            method: 'fuzzy',
            panelType: 'transparent',
            noPad: true,
            default: '',
            focus: true,
            placeholder: 'Search product names or E-numbers',
        },
        {
            title: 'Must be:',
            operator: 'add',
            type: 'checkbox',
            field: 'mustBe',
            method: 'strict',
            panelType: 'light',
            default: [],
            dataObserver: of(DataFilterService.getMustBeFilter()),
            checkboxStyle: 'pills',
        },
    ];

    constructor(
        private readonly title: Title,
        private readonly aiService: ApplicationInsightsService,
        private readonly store: StorageService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly portalsService: PortalsService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.store.products.pipe(takeUntil(this.unsubscribe)).subscribe(products => {
            this.products = products;
            this.productsSubject.next(products);
            this.cd.markForCheck();
        });

        combineLatest([this.portalsService.portalObs, this.productsSubject])
            .pipe(delay(0), takeUntil(this.unsubscribe))
            .subscribe(([currentPortal, products]) => {
                products.forEach(product => {
                    if (product.declarationsByPortal) {
                        product.declaration = product.declarationsByPortal ? product.declarationsByPortal[currentPortal.portal] : undefined;
                    }
                });

                this.filtered.length = 0;
                this.filtered = this.filtered.concat(products.filter(x => x.portals?.includes(currentPortal.portal)));

                this.cd.detectChanges();
            });

        this.store.getProducts();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.productsSubject.complete();
    }

    public setFiltered(result: FilterResult): void {
        if (DataFilterService.isProductTypes(result.data)) {
            this.productsSubject.next(result.data);
        } else {
            this.productsSubject.next([]);
        }
    }
}
