<div class="status-menu-dropdown">
    <div
        class="status-menu__status-bar-container status-menu__status-bar-container--dropdown status-menu__status-bar-container--dark"
        [ngClass]="{
            'status-menu__status-bar--loading': activeState === 'connecting',
            'status-menu__status-bar--complete': activeState === 'complete',
            'status-menu__status-bar--error': activeState === 'error'
        }"
    >
        <ng-container *ngTemplateOutlet="statusIcons; context: { secondary: true }"></ng-container>
        <div
            *ngIf="activeState === 'inprog'"
            class="status-menu__status-bar status-menu__status-bar--loading"
            [style.transform]="'translate3d(' + currentProgress + '%, 0, 0)'"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            [attr.aria-valuenow]="currentProgress"
            aria-label="Synchronization is running at the moment"
        ></div>
    </div>

    <ng-container *ngIf="activeState !== 'error'">
        <div class="status-menu-dropdown__options">
            <app-sh-offline-toggle (offlineChange)="handleOfflineChange($event)"></app-sh-offline-toggle>
        </div>
        <div class="status-menu-dropdown__log">
            <app-sh-expansion-panel>
                <app-sh-expansion-panel-header>Update history</app-sh-expansion-panel-header>
                <app-sh-expansion-panel-content>
                    <div *ngFor="let entry of logs" class="status-menu-dropdown__log-entry">
                        {{ entry }}
                    </div>
                </app-sh-expansion-panel-content>
            </app-sh-expansion-panel>
        </div>
    </ng-container>
</div>
