import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UpdateService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(private readonly updates: SwUpdate, private readonly appRef: ApplicationRef, private readonly snackbar: MatSnackBar) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public checkForUpdate(): void {
        if (!this.updates.isEnabled) {
            console.warn('Service worker is not enabled');
            return;
        }

        console.log('Listening for updated app versions');

        this.updates.available.pipe(takeUntil(this.unsubscribe)).subscribe(event => {
            console.log('New version detected', event.current, event.available);

            const snack = this.snackbar.open('There is a new version available. Reload to install.', 'Reload', {
                duration: 10000,
                politeness: 'assertive',
            });

            snack
                .onAction()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    console.log('User has accepted to install');
                    this.updates.activateUpdate().then(() => {
                        console.log('New version downloaded', event.available);
                        window.location.reload();
                    });
                });
        });

        this.updates.activated.pipe(takeUntil(this.unsubscribe)).subscribe(event => {
            console.log('New version installed', event.previous, event.current);
        });

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

        everySixHoursOnceAppIsStable$.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updates.checkForUpdate());
    }
}
