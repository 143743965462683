import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appTableCellContent]',
})
export class TableCellContentDirective {
    @Input() public appTableCellContent: string;

    constructor(public templateRef: TemplateRef<any>) {}
}
