import { Component, HostBinding, ChangeDetectionStrategy, Input } from '@angular/core';
import { PanelTypes } from './sh-panel.component';

@Component({
    selector: 'app-sh-panel-header',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShPanelHeaderComponent {
    @Input() public type: PanelTypes;
    @Input() public noPad = false;

    @HostBinding('class') get classes(): string {
        const classList = ['sh-panel__header'];

        if (this.type) {
            classList.push(`sh-panel__header--${this.type}`);
        }

        if (this.noPad) {
            classList.push('sh-panel__header--no-pad');
        }

        return classList.join(' ');
    }
}
