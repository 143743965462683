import { Injectable, OnDestroy } from '@angular/core';
import { PortalsService } from '@features/portals/portals.service';
import { SCHEMA_VERSION } from '@models/constants';
import { Settings, StoredFile } from '@models/interfaces';
import {
    ApplicationAreaType,
    MarketingFileType,
    ProductType,
    RecipeType,
    StorageNameType,
    StorageTypes
} from '@models/types';
import { openDB } from 'idb';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class StorageService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    private stores = ['recipes', 'products', 'marketingFiles', 'files', 'images', 'translations', 'applicationAreas', 'marketingFilesApplicationAreas', 'settings'];
    dbPromise = openDB('MyPalsgaard', SCHEMA_VERSION, {
        upgrade(db) {
            for (const os of db.objectStoreNames) {
                db.deleteObjectStore(os);
            }
            db.createObjectStore('recipes').createIndex('id', 'id');
            db.createObjectStore('products').createIndex('id', 'id');
            db.createObjectStore('marketingFiles').createIndex('id', 'id');
            db.createObjectStore('images').createIndex('id', 'id');
            db.createObjectStore('files').createIndex('id', 'id');
            db.createObjectStore('applicationAreas').createIndex('id', 'id');
            db.createObjectStore('marketingFilesApplicationAreas');
            db.createObjectStore('settings');
            db.createObjectStore('translations');
        },
    });

    data: StorageTypes[] = [];
    recipes = new BehaviorSubject<RecipeType[]>([]);
    products = new BehaviorSubject<ProductType[]>([]);
    marketingFiles = new BehaviorSubject<MarketingFileType[]>([]);
    applicationAreas = new BehaviorSubject<ApplicationAreaType[]>([]);
    marketingFilesApplicationAreas = new BehaviorSubject<string[]>([]);

    constructor(private readonly portals: PortalsService, private readonly notification: NotificationService) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private parseData(inputData: StorageTypes[]) {
        try {
            return inputData.map((item: StorageTypes) => {
                return item;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async setData(type: StorageNameType, data: StorageTypes | StorageTypes[] | Settings, key?: string) {
        try {
            const trans = (await this.dbPromise).transaction(type, 'readwrite');
            if (Array.isArray(data)) {
                this.parseData(data).forEach((item: any) => {
                    if (item.id) {
                        trans.store.put(item, item.id);
                    }
                    if (item.fileId) {
                        trans.store.put(item, item.fileId);
                    }
                });
            } else if (key) {
                trans.store.put(data, key);
            }
            await trans.done;
        } catch (error: any) {
            if (error.name === 'QuotaExceededError') {
                const availableDiskSpace = await this.estimateAvailableDiskSpace();
                const msg = `The device ran out of allocated disk space (available: ${
                    availableDiskSpace ? this.formatDiskSpace(availableDiskSpace) : 'N/A'
                }. Try to delete some files and refresh the webpage`;
                this.notification
                    .openSnackBar(msg, undefined, 'reload')
                    .onAction()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => window.location.reload());
            } else {
                throw error;
            }
        }
    }

    private async getData(type: StorageNameType, key?: string | number) {
        try {
            let data: any;
            if (key) {
                data = await (await this.dbPromise).get(type, key);
            } else {
                data = await (await this.dbPromise).getAll(type);
            }
            return data;
        } catch (error: any) {
            throw error;
        }
    }

    // TODO: Add type
    public getImage(id: string): Promise<any> {
        return this.getData('images', id);
    }

    public getProductById(id: string): Promise<ProductType> {
        return this.getData('products', id);
    }

    public async getProducts() {
        const products = await this.getData('products');
        this.products.next(products);
    }

    public async getMarketingFiles() {
        const marketingFiles = await this.getData('marketingFiles');
        this.marketingFiles.next(marketingFiles);
    }

    public getRecipeById(id: number): Promise<RecipeType> {
        return this.getData('recipes', id);
    }

    public async getRecipes() {
        const recipes = await this.getData('recipes');
        this.recipes.next(recipes);
    }

    public getFileById(id: string | number): Promise<StoredFile> {
        return this.getData('files', String(id));
    }

    public async getApplicationAreas() {
        const applicationAreas = await this.getData('applicationAreas');
        this.applicationAreas.next(applicationAreas);
    }

    public async getMarketingFilesApplicationAreas() {
        const marketingFilesApplicationAreas = await this.getData('marketingFilesApplicationAreas');
        this.marketingFilesApplicationAreas.next(marketingFilesApplicationAreas);
    }

    public async getTranslations(langKey: string) {
        const translations = await this.getData('translations', langKey);
        return translations;
    }

    public async setSettings(key: keyof Settings, value: any) {
        const currentPortal = this.portals.getCurrentPortal();
        const settings = (await this.getSettings()) || {};

        settings[key] = value;
        this.setData('settings', settings, currentPortal);
    }

    public getSettings(portal?: string): Promise<Settings | undefined> {
        const currentPortal = portal || this.portals.getCurrentPortal();
        return this.getData('settings', currentPortal);
    }

    public getLatestApplicationArea(): string {
        if (window && 'Storage' in window) {
            return localStorage.getItem('latestApplicationArea') ?? '';
        }
        return '';
    }

    public setLatestApplicationArea(value: string): void {
        if (window && 'Storage' in window) {
            localStorage.setItem('latestApplicationArea', value);
        }
    }

    public async nukeData() {
        await Promise.all(this.stores.map(async (store: string) => (await this.dbPromise).clear(store)));
        this.products.next([]);
        this.recipes.next([]);
    }

    public async hasData(type: StorageNameType) {
        const count = (await this.dbPromise).count(type);
        const hasData = (await count) > 0;
        return hasData;
    }

    public async estimateAvailableDiskSpace(): Promise<number> {
        let availableDiskSpace = 0;

        if ('estimate' in navigator.storage) {
            const estimate = await navigator.storage.estimate();

            if (estimate.quota && estimate.usage) {
                if (estimate.quota && estimate.usage) {
                    availableDiskSpace = estimate.quota - estimate.usage;
                }
            }
        }

        return availableDiskSpace;
    }

    public formatDiskSpace(availableDiskSpace: number): string {
        if (availableDiskSpace) {
            availableDiskSpace = availableDiskSpace / 1024 / 1024;
        }

        return `${availableDiskSpace.toFixed(1)} Mb`;
    }
}
