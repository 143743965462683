import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TableLinkType } from '@models/interfaces';

import { ColumnHead, RowMetadata } from '../table.model';
import { MarketingFileIdAndType } from '@features/marketing-files/marketing-files.component';

@Component({
    selector: 'app-sh-table-row',
    templateUrl: './sh-table-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShTableRowComponent {
    @Input() public indexRow: number;
    @Input() public isSelectable: number;
    @Input() public contentRow: RowMetadata<any> = [];
    @Input() public columns: ColumnHead<any>[] = [];
    @Input() public templates: ElementRef[] = [];
    @Output() public linkClicked: EventEmitter<TableLinkType> = new EventEmitter();
    @Output() public marketingFileIdClicked: EventEmitter<MarketingFileIdAndType> = new EventEmitter();
    @Output() public marketingFileIdClickedDownload: EventEmitter<MarketingFileIdAndType> = new EventEmitter();

    public trackByFunction = (index: number, item: ColumnHead<unknown>) => {
        return item.fieldID || index;
    };

    public onLinkClicked(event: MouseEvent, link: TableLinkType) {
        this.linkClicked.emit(link);
        if (link.externalLink) {
            event.preventDefault();
            return false;
        }
    }

    public onMarketingFileIdClicked(event: MouseEvent, fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClicked.emit(fileIdAndType);
    }

    public onMarketingFileIdClickedDownload(event: MouseEvent, fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClickedDownload.emit(fileIdAndType);
    }

    public sanitize(value: string) {
        if (typeof value !== 'string') {
            return '-';
        }
        return value;
    }
}
