<app-sh-panel>
    <app-sh-panel-content>
        <div class="front-page__container">
            <h1 class="text__heading text__heading--huge">Welcome to My Palsgaard</h1>
            <div class="front-page__content">
                <div class="front-page__item">
                    <a class="front-page__item-link-wrapper" [routerLink]="(authenticated$ | async) ? '/products' : ''" (click)="handleLogin()">
                        <div class="front-page__item-image-wrapper">
                            <img class="front-page__item-image" src="/assets/images/product-catalogue.jpg" alt="product-catalogue" />
                        </div>
                        <div class="front-page__item-link">
                            <h1 class="text__heading">Product Catalogue</h1>
                        </div>
                    </a>
                </div>
                <div class="front-page__item">
                    <a class="front-page__item-link-wrapper" [routerLink]="(authenticated$ | async) ? '/solution-finder' : ''" (click)="handleLogin()">
                        <div class="front-page__item-image-wrapper">
                            <img class="front-page__item-image" src="/assets/images/solution-finder.jpg" alt="solution-finder" />
                        </div>
                        <div class="front-page__item-link">
                            <h1 class="text__heading">Solution Finder</h1>
                        </div>
                    </a>
                </div>
                <div class="front-page__item">
                    <a class="front-page__item-link-wrapper" [routerLink]="(authenticated$ | async) ? '/marketing-files' : ''" (click)="handleLogin()">
                        <div class="front-page__item-image-wrapper">
                            <img class="front-page__item-image" src="/assets/images/marketing-files.jpg" alt="marketing-files" />
                        </div>
                        <div class="front-page__item-link">
                            <h1 class="text__heading">Marketing Files</h1>
                        </div>
                    </a>
                </div>
                <div class="front-page__item">
                    <a class="front-page__item-link-wrapper" [routerLink]="(authenticated$ | async) ? '/user-guide' : ''" (click)="handleLogin()">
                        <div class="front-page__item-image-wrapper">
                            <img class="front-page__item-image" src="/assets/images/user-guide.jpg" alt="user-guide" />
                        </div>
                        <div class="front-page__item-link">
                            <h1 class="text__heading">User Guide</h1>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </app-sh-panel-content>
</app-sh-panel>
