import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

import { ALLOWED_BUTTON_COLORS, ALLOWED_BUTTON_SIZES, ALLOWED_BUTTON_TYPES, ButtonColors, ButtonSizes, ButtonTypes, TextAlignments } from './types';

@Component({
    selector: 'app-sh-button, button[shButton]',
    templateUrl: './sh-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShButtonComponent implements OnInit, OnChanges {
    private _size: ButtonSizes = 'medium';
    private _type: ButtonTypes = 'primary';
    private _color: ButtonColors = 'black';
    private _disabled = false;

    @Input() public set size(value: ButtonSizes) {
        value = value || 'medium';
        if (!this.hasAllowedValue(value, ALLOWED_BUTTON_SIZES, 'ALLOWED_BUTTON_SIZES')) {
            return;
        }
        if (this._size !== value) {
            this.cdr.markForCheck();
        }
        this._size = value;
    }
    public get size() {
        return this._size;
    }

    @Input() public set type(value: ButtonTypes) {
        value = value || 'primary';
        if (!this.hasAllowedValue(value, ALLOWED_BUTTON_TYPES, 'ALLOWED_BUTTON_TYPES')) {
            return;
        }
        if (this._type !== value) {
            this.cdr.markForCheck();
        }
        this._type = value;
    }
    public get type() {
        return this._type.toLowerCase().replace('_', '-');
    }

    @Input() public set color(value: ButtonColors) {
        value = value || 'black';
        if (!this.hasAllowedValue(value, ALLOWED_BUTTON_COLORS, 'ALLOWED_BUTTON_COLORS')) {
            return;
        }
        if (this._color !== value) {
            this.cdr.markForCheck();
        }
        this._color = value;
    }
    public get color() {
        return this._color;
    }

    @Input() public set disabled(value: boolean) {
        const disabled = value;

        if (disabled !== this._disabled) {
            this._disabled = disabled;
            this.cdr.markForCheck();
        }
    }
    public get disabled() {
        return this._disabled;
    }

    @Input() public label: string;
    @Input() public textAlign: TextAlignments = 'center';
    @Input() public hasIcon = false;
    @Input() public isRounded = false;
    @Input() public hasPartition = false;

    constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2, public buttonElement: ElementRef<HTMLElement>) {}

    ngOnInit() {
        this.renderer.addClass(this.buttonElement.nativeElement, 'sh-button__container');
        this.setClasses();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.keys(changes).some(k => changes[k].isFirstChange())) {
            return;
        }

        this.setClasses();
        this.cdr.markForCheck();
    }

    private setClasses(): void {
        const el = this.buttonElement.nativeElement;
        const setClass = (add: boolean, className: string) => {
            if (add) {
                this.renderer.addClass(el, className);
            } else {
                this.renderer.removeClass(el, className);
            }
        };

        setClass(this.disabled, `sh-button__${this.type}--is-disabled`);
        setClass(this.isRounded, `sh-button__${this.type}--is-rounded`);
        setClass(this.hasPartition, `sh-button__${this.type}--has-partition`);
        setClass(this.size === 'medium', 'sh-button__container--medium');
        setClass(this.size === 'large', 'sh-button__container--large');

        for (const type of Object.keys(ALLOWED_BUTTON_TYPES)) {
            const sanitizedType = type.toLowerCase().replace('_', '-');
            setClass(this.type === sanitizedType, `sh-button__${sanitizedType}`);
        }

        for (const color of Object.keys(ALLOWED_BUTTON_COLORS)) {
            const sanitizedColor = color.toLowerCase();
            setClass(this.color === sanitizedColor, `sh-button__${this.type}--${sanitizedColor}`);
        }
    }

    private hasAllowedValue(value: any, collection: any, collectionName: string): boolean {
        let hasAllowedType = false;
        for (const type in collection) {
            if (value === type.toLowerCase()) {
                hasAllowedType = true;
                break;
            }
        }
        if (!hasAllowedType) {
            console.warn(`The value '${value}' does not belong to the collection ${collectionName}`);
            return false;
        }
        return true;
    }
}
