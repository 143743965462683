import { Injectable } from '@angular/core';
import { ShSelectComponent } from './sh-select.component';

@Injectable()
export class ShDropdownService {
    private select: ShSelectComponent;

    public register(select: ShSelectComponent) {
        this.select = select;
    }

    public getSelect(): ShSelectComponent {
        return this.select;
    }
}
