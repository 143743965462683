import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieService {
    public setCookie(name: string, value: string | number | boolean, days = 7, path = '/') {
        let expires = '';
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
        document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=' + path;
    }

    public getCookie(name: string): string {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    }

    public deleteCookie(name: string, path = '/') {
        this.setCookie(name, '', -1, path);
    }
}
