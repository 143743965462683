<span
    class="sh-button__text"
    [class.sh-button__text--text-align-left]="textAlign === 'left'"
    [class.sh-button__text--text-align-right]="textAlign === 'right'"
    [class.sh-button__text--text-align-center]="textAlign === 'center'"
    [class.sh-button__text--with-icon]="hasIcon"
>
    {{ label }}
    <ng-content></ng-content>
</span>
