import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    ViewChild
} from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import Player from '@vimeo/player';

@Component({
    selector: 'app-vimeo-player',
    template: `
        <div class="vimeo-player">
            <div class="vimeo-player__video" [hidden]="!showVideoFrame" #videoWrapper></div>
            <div
                class="vimeo-player__spinner"
                *ngIf="isFetching || (!hideSpinner && !showVideoFrame)"
            >
                <mat-spinner></mat-spinner>
            </div>
            <div class="vimeo-player__error" *ngIf="vimeoError">
                {{ vimeoError }}
            </div>
        </div>
    `,
    styleUrls: ['./vimeo-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoPlayerComponent implements AfterViewInit {
    private readonly isBrowser: boolean;
    public showVideoFrame = false;
    public isFetching = false;
    public player?: any;
    public vimeoError?: string;
    @Input() public loop = false;
    @Input() public controls = true;
    @Input() public muted = false;
    @Input() public videoUrl = '';
    @Input() public autoplay = true;
    @Input() public hideSpinner = false;
    @ViewChild('videoWrapper') public videoWrapper?: ElementRef<HTMLDivElement>;

    constructor(
        private cd: ChangeDetectorRef,
        @Inject(PLATFORM_ID) platformId: any,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public ngAfterViewInit(): void {
        if (this.isBrowser && this.videoUrl) {
            this.isFetching = true;
            this.setPlayer();
        }
    }

    private setPlayer(): void {
        if (this.videoWrapper) {
            this.player = new Player(this.videoWrapper.nativeElement, {
                url: this.videoUrl,
                controls: this.controls,
                loop: this.loop,
                muted: this.muted,
                background: !this.controls,
                title: !this.controls,
                autoplay: this.autoplay,
                byline: false,
                autopause: true,
            });

            this.player
                .ready()
                .then(() => {
                    setTimeout(() => {
                        this.showVideoFrame = true;
                        this.isFetching = false;
                        this.cd.markForCheck();
                    }, 5000);

                    this.player.on('progress', () => {
                        this.player.off('progress');
                        this.showVideoFrame = true;
                        this.isFetching = false;
                        this.cd.detectChanges();
                    });
                })
                .catch((error: any) => {
                    this.vimeoError = error?.message || error;
                    this.cd.markForCheck();
                });
        }
    }
}
