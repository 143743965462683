import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@core/notification.service';
import { NotificationType } from '@models/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-notification',
    templateUrl: 'notification.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    constructor(private notificationService: NotificationService, private readonly cdr: ChangeDetectorRef) {}

    public notification: NotificationType = {
        message: '',
        complete: true,
    };

    ngOnInit() {
        this.notificationService.notification.pipe(takeUntil(this.unsubscribe)).subscribe(notification => {
            this.notification = notification;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
