import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sh-progress',
    template: `
        <figure class="progress">
            <svg class="progress__container">
                <rect class="progress__value" attr.width="{{ v }}%" />
            </svg>
        </figure>
    `,
})
export class ShProgressComponent {
    v = 0;
    @Input() public set value(value: number) {
        // For fancyness.
        this.v = 0;
        setInterval(() => {
            this.v = value;
        }, 5);
    }
}
