<div
    class="sh-expansion-panel sh-expansion-panel__container"
    [ngClass]="{
        'sh-expansion-panel__container--border-bottom': type === 'primary',
        'sh-expansion-panel__container--no-border': type === 'secondary',
        'sh-expansion-panel__container--collapsed': !expanded
    }"
>
    <app-sh-panel>
        <app-sh-panel-header>
            <div
                class="sh-expansion-panel__header"
                (click)="headerClick()"
                role="button"
                [attr.aria-label]="ariaLabel"
                [class.sh-expansion-panel__header--left]="togglePosition === 'left'"
            >
                <ng-content select="app-sh-expansion-panel-header"></ng-content>
                <div *ngIf="!hideToggle" class="sh-expansion-panel__icon" aria-hidden="true">
                    <span
                        [ngClass]="{
                            'icon-arrow-down': !expanded,
                            'icon-arrow-up': expanded
                        }"
                    ></span>
                </div>
            </div>
        </app-sh-panel-header>
        <app-sh-panel-content *ngIf="expanded">
            <div class="sh-expansion-panel__content">
                <ng-content select="app-sh-expansion-panel-content"></ng-content>
            </div>
        </app-sh-panel-content>
    </app-sh-panel>
</div>
