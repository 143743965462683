<div
    class="checkboxes"
    [class.checkboxes-style--pills]="checkboxStyle === 'pills'"
    [class.checkboxes-style--columns]="checkboxStyle === 'columns'"
    aria-label="List of checkboxes"
>
    <label
        *ngFor="let option of data"
        class="checkboxes__item"
        role="checkbox"
        [attr.aria-label]="option.label"
        [attr.aria-checked]="option.checked"
        [class.checkboxes__item--checked]="option.checked"
    >
        <input class="element-hidden" type="checkbox" [(ngModel)]="option.checked" (change)="registerOnChange()" />
        {{ option.label }}

        <i *ngIf="option.checked && checkboxStyle === 'columns'" aria-hidden class="checkboxes__icon icon-checkmark"></i>
    </label>
</div>
