<div class="portals top-bar__item" role="button" aria-label="Show all available portals" (click)="showDropdown()" *ngIf="states.initialLoad">
    <div aria-hidden="true" class="portals__icon" [inlineSVG]="'assets/icons/globe.svg'"></div>
    <app-sh-select
        [selected]="currentPortal"
        (changeEvent)="setPortal($event)"
        [ariaLabel]="'Selected portal'"
        aria-label="Select a portal"
        [attr.aria-valuetext]="currentPortalName || 'No portal selected'"
        class="portals__select"
    >
        <app-sh-select-option *ngFor="let portal of portals; trackBy: trackByFn" [key]="portal?.id" [value]="portal?.value"></app-sh-select-option>
    </app-sh-select>
</div>
