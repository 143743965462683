import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StorageService } from '@core/storage.service';
import { ShSelectComponent } from '@shared/sh-select/sh-select.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-filter-marketing-files-select',
    templateUrl: './sh-filter-marketing-files-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShFilterMarketingFilesSelectComponent implements OnInit, OnDestroy {
    @Input() public type: string;
    @Input() public placeholderOption: string;
    @Input() public label: string;
    @Input() public ariaLabel: string;
    @Output() public valueChange = new EventEmitter<string[]>();

    @ViewChild(ShSelectComponent) private selectComponent: ShSelectComponent;

    public marketingFilesApplicationAreas: string[] = [];

    private isMarketingFilesApplicationAreas = false;

    private unsubscribe = new Subject<void>();

    constructor(
        private readonly store: StorageService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.isMarketingFilesApplicationAreas = this.type === 'marketingFilesApplicationAreas';

        if (this.isMarketingFilesApplicationAreas) {
            this.store.marketingFilesApplicationAreas.pipe(takeUntil(this.unsubscribe)).subscribe(marketingFilesApplicationAreas => {
                this.marketingFilesApplicationAreas.length = 0;
                this.marketingFilesApplicationAreas = this.marketingFilesApplicationAreas.concat(marketingFilesApplicationAreas);

                this.cd.markForCheck();
            });

            this.store.getMarketingFilesApplicationAreas();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public showDropdown() {
        if (this.selectComponent) {
            this.selectComponent.showDropdown();
        }
    }

    public registerOnChange(currentValue: string) {
        let value = currentValue;
        if (value === 'select') {
            value = '';
        }
        this.valueChange.emit(value ? [value] : []);
    }

    public trackByFn(index: number, item: string) {
        return item;
    }
}
