import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataFilterService } from '@core/data-filter.service';
import { StorageService } from '@core/storage.service';
import { PortalsService } from '@features/portals/portals.service';
import { RecipeGroupViewModel } from '@models/api';
import { ApplicationArea, FilterItemType, FilterResult, FilterType } from '@models/interfaces';
import { RecipeType } from '@models/types';
import { groupBy, sortBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-solution-finder',
    templateUrl: './solution-finder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionFinderComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private paginator: MatPaginator;
    @ViewChild('counter') private counter: ElementRef;

    public products: RecipeType[] = [];
    public applicationAreas: ApplicationArea[] = [];
    public dataSource?: MatTableDataSource<RecipeGroupViewModel>;
    public filters: FilterType[] = [
        {
            title: 'Application Area',
            operator: 'add',
            type: 'select',
            field: 'applicationArea',
            method: 'strict',
            noPad: true,
            panelType: 'light',
            default: [],
            dataObserver: new BehaviorSubject<FilterType | null>(null),
        },
        {
            title: 'Sub application Area',
            operator: 'add',
            type: 'select',
            field: 'subApplicationArea',
            method: 'strict',
            noPad: true,
            panelType: 'light',
            default: [],
            dataObserver: new BehaviorSubject<FilterType | null>(null),
        },
        {
            title: 'Finished product',
            operator: 'add',
            type: 'text',
            field: '__indexValue',
            method: 'fuzzy',
            panelType: 'transparent',
            noPad: true,
            default: '',
            placeholder: 'Search finished products',
        },
        {
            title: 'Must be:',
            operator: 'add',
            type: 'checkbox',
            field: 'mustBe',
            method: 'strict',
            panelType: 'light',
            default: [],
            dataObserver: new BehaviorSubject<FilterType | null>(null),
            checkboxStyle: 'pills',
        },
    ];
    public groupedProducts: RecipeGroupViewModel[] = [];
    public isFilterActive: boolean;

    private unsubscribe = new Subject<void>();

    constructor(private readonly store: StorageService, private readonly portalsService: PortalsService, private readonly cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.getRecipes();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getRecipes() {
        combineLatest([this.portalsService.portalObs, this.store.recipes])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([portal, products]) => {
                this.filters.forEach((filter: FilterType) => {
                    if (filter.dataObserver) {
                        filter.dataObserver.next(
                            filter.field === 'mustBe' ? DataFilterService.getMustBeFilter() : this.parseCheckboxes(filter.field, products)
                        );
                    }
                });
                this.products.length = 0;
                this.products = this.products.concat(products.filter(n => n.portals?.includes(portal.portal)));

                if (this.products && this.products.length > 0) {
                    this.setFiltered({
                        data: this.products,
                        query: {},
                        queryCount: 0
                    });
                }

                // Fixes "Expression has changed after it was checked" error
                this.cdr.detectChanges();
            });
        this.store.getRecipes();
    }

    public setFiltered(result: FilterResult): void {
        this.groupedProducts.length = 0;

        if (DataFilterService.isRecipeTypes(result.data)) {
            const groupByName = Object.values(groupBy(result.data, 'name'));
            this.groupedProducts = groupByName.map(product => ({
                name: product[0].name,
                recipes: product,
            }));

            this.groupedProducts = sortBy(this.groupedProducts, 'name');

            this.dataSource = new MatTableDataSource(this.groupedProducts);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.paginator.pageIndex = 0;
        }
        this.isFilterActive = true;
    }

    private parseCheckboxes(field: string, data: RecipeType[]): FilterItemType {
        const checkboxes: FilterItemType = { field, values: [] };

        const checkboxValues: any[] = [];
        data.forEach(product => {
            const productField = (product as any)[field];
            if (productField && Array.isArray(productField)) {
                productField.forEach(item => {
                    const checkbox = {
                        label: item.displayName,
                        key: item.key,
                        checked: false,
                    };
                    checkboxValues.push(checkbox);
                });
            }
        });

        // Filter unique.
        const unique = {};
        checkboxValues.map(item => ((unique as any)[item.key] = item));
        checkboxes.values = Object.keys(unique).map(key => (unique as any)[key]);
        return checkboxes;
    }

    public onPageChange() {
        if (this.counter.nativeElement) {
            // Ensures that page size change event also scrolls to counter
            setTimeout(() => {
                this.counter.nativeElement.scrollIntoView();
            });
        }
    }

    public trackByFn(index: number, item: RecipeType) {
        return item.id || index;
    }
}
