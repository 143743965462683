<ng-container *ngFor="let filter of filters" [ngSwitch]="filter.type">
    <app-sh-panel *ngSwitchCase="'text'" type="transparent">
        <app-sh-panel-header [noPad]="filter.noPad">
            <h3>{{ filter.title }}</h3>
        </app-sh-panel-header>
        <app-sh-panel-content [noPad]="filter.noPad">
            <app-sh-input
                (valueChange)="onValueChange($event, filter, true)"
                [placeholder]="filter.placeholder"
                [focus]="filter.focus"
                [ariaLabel]="filter.placeholder"
            ></app-sh-input>
        </app-sh-panel-content>
    </app-sh-panel>

    <app-sh-panel type="row" *ngSwitchCase="'checkbox'">
        <app-sh-panel-header [type]="filter.headerType">
            <h3>{{ filter.title }}</h3>
        </app-sh-panel-header>
        <app-sh-panel-content type="wide">
            <app-sh-checkboxes
                [data]="filter.data"
                (valueChange)="onValueChange($event, filter)"
                [checkboxStyle]="filter.checkboxStyle"
            ></app-sh-checkboxes>
        </app-sh-panel-content>
    </app-sh-panel>

    <app-sh-panel *ngSwitchCase="'select'" type="transparent">
        <app-sh-panel-header [noPad]="filter.noPad">
            <div class="sh-filter-select__header-container">
                <h3>{{ filter.title }}</h3>
            </div>
        </app-sh-panel-header>
        <app-sh-panel-content [noPad]="filter.noPad">
            <app-sh-filter-select
                [type]="filter.field"
                [ariaLabel]="'Select a ' + filter.title"
                [placeholderOption]="filter.placeholder"
                (valueChange)="onValueChange($event, filter)"
            >
            </app-sh-filter-select>
        </app-sh-panel-content>
    </app-sh-panel>

    <app-sh-panel *ngSwitchCase="'marketingFilesSelect'" type="transparent">
        <app-sh-panel-header [noPad]="filter.noPad">
            <div class="sh-filter-select__header-container">
                <h3>{{ filter.title }}</h3>
            </div>
        </app-sh-panel-header>
        <app-sh-panel-content [noPad]="filter.noPad">
            <app-sh-filter-marketing-files-select
                [type]="filter.field"
                [ariaLabel]="'Select a ' + filter.title"
                [placeholderOption]="filter.placeholder"
                (valueChange)="onValueChange($event, filter)"
            >
            </app-sh-filter-marketing-files-select>
        </app-sh-panel-content>
    </app-sh-panel>
</ng-container>
