import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';

import { ShButtonComponent } from './sh-button/sh-button.component';
import { ShCheckboxesComponent } from './sh-checkboxes/sh-checkboxes.component';
import { ShCounterComponent } from './sh-counter/sh-counter.component';
import { ShDataFilterComponent } from './sh-data-filter/sh-data-filter.component';
import { ShDividerComponent } from './sh-divider/sh-divider.component';
import { ShExpansionPanelContentComponent } from './sh-expansion-panel/sh-expansion-panel-content.component';
import { ShExpansionPanelHeaderComponent } from './sh-expansion-panel/sh-expansion-panel-header.component';
import { ShExpansionPanelComponent } from './sh-expansion-panel/sh-expansion-panel.component';
import { ShFilterSelectComponent } from './sh-filter-select/sh-filter-select.component';
import { ShInputComponent } from './sh-input/sh-input.component';
import { ShOfflineToggleComponent } from './sh-offline-toggle/sh-offline-toggle.component';
import { ShPanelContentComponent } from './sh-panel/sh-panel-content.component';
import { ShPanelHeaderComponent } from './sh-panel/sh-panel-header.component';
import { ShPanelComponent } from './sh-panel/sh-panel.component';
import { ShProductListComponent } from './sh-product-list/sh-product-list.component';
import { ShProgressComponent } from './sh-progress/sh-progress.component';
import { ShRecipeComponent } from './sh-recipe/sh-recipe.component';
import { ShDropdownComponent } from './sh-select/sh-dropdown.component';
import { ShSelectOptionComponent } from './sh-select/sh-select-option.component';
import { ShSelectComponent } from './sh-select/sh-select.component';
import { ShTablePaginatorComponent } from './sh-table/sh-table-paginator/sh-table-paginator.component';
import { ShTableRowComponent } from './sh-table/sh-table-row/sh-table-row.component';
import { ShTableComponent } from './sh-table/sh-table.component';
import { TableCellContentDirective } from './sh-table/table-cell-content.directive';
import { ShTabsComponent } from './sh-tabs/sh-tabs.component';
import { ShDocumentViewerComponent } from '@shared/sh-document-viewer/sh-document-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShMarketingFilesListComponent } from '@shared/sh-marketing-files-list/sh-marketing-files-list.component';
import { VimeoPlayerComponent } from '@shared/vimeo-player/vimeo-player.component';
import {
    ShFilterMarketingFilesSelectComponent
} from '@shared/sh-filter-marketing-files-select/sh-filter-marketing-files-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InlineSVGModule.forRoot(),
        OverlayModule,
        PortalModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        RouterModule,
        PdfViewerModule,
    ],
    declarations: [
        ShPanelComponent,
        ShPanelHeaderComponent,
        ShPanelContentComponent,
        ShSelectComponent,
        ShFilterSelectComponent,
        ShFilterMarketingFilesSelectComponent,
        ShDropdownComponent,
        ShSelectOptionComponent,
        ShButtonComponent,
        ShTableComponent,
        ShTablePaginatorComponent,
        ShProgressComponent,
        ShExpansionPanelComponent,
        ShExpansionPanelHeaderComponent,
        ShExpansionPanelContentComponent,
        ShInputComponent,
        TableCellContentDirective,
        ShProductListComponent,
        ShDataFilterComponent,
        ShCheckboxesComponent,
        ShTabsComponent,
        ShTableRowComponent,
        ShDividerComponent,
        ShRecipeComponent,
        ShCounterComponent,
        ShOfflineToggleComponent,
        ShDocumentViewerComponent,
        ShMarketingFilesListComponent,
        VimeoPlayerComponent,
    ],
    exports: [
        ShPanelComponent,
        ShPanelHeaderComponent,
        ShPanelContentComponent,
        ShSelectComponent,
        ShDropdownComponent,
        ShSelectOptionComponent,
        ShFilterSelectComponent,
        ShFilterMarketingFilesSelectComponent,
        ShButtonComponent,
        ShTableComponent,
        ShTablePaginatorComponent,
        ShProgressComponent,
        ShExpansionPanelComponent,
        ShExpansionPanelHeaderComponent,
        ShExpansionPanelContentComponent,
        ShInputComponent,
        TableCellContentDirective,
        ShProductListComponent,
        ShDataFilterComponent,
        ShCheckboxesComponent,
        ShTabsComponent,
        ShDividerComponent,
        ShRecipeComponent,
        ShCounterComponent,
        ShOfflineToggleComponent,
        ShDocumentViewerComponent,
        VimeoPlayerComponent,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        ShMarketingFilesListComponent,
    ],
})
export class SharedModule {}
