import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationInsightsService } from '@core/application-insights.service';
import { v1 } from '@models/api';
import { REFRESH_TOKEN_COOKIE } from '@models/constants';
import { AccountInformation } from '@models/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { qs } from 'url-parse';

import { CookieService } from './cookie.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private endpoint = '/api';
    public accountInformation: BehaviorSubject<AccountInformation | null>;
    public accountObs: Observable<AccountInformation | null>;
    public authToken = '';
    public isLoggedIn = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly cookie: CookieService,
        private readonly http: HttpClient,
        private readonly notification: NotificationService,
        private readonly aiService: ApplicationInsightsService
    ) {
        let storedAccount: AccountInformation | null = null;
        try {
            if (window && 'Storage' in window) {
                const storedAccountString = localStorage.getItem('account');
                if (storedAccountString) {
                    storedAccount = JSON.parse(storedAccountString);
                }
            }
        } catch {}

        this.accountInformation = new BehaviorSubject(storedAccount || null);
        this.accountObs = this.accountInformation.asObservable();
    }

    public async login() {
        try {
            const url = await this.http
                .get(`${this.endpoint}/v1/account/loginurl`, {
                    headers: {
                        accept: 'text/plain',
                    },
                    responseType: 'text',
                })
                .toPromise();
            window.location.href = `${url}?returnUrl=${window.location.origin}`;
        } catch (error: any) {
            this.notification.openSnackBar(`Failed to get login url while authenticating (${error.message})`, 0);
            console.error('Could not log in', error.message);
            throw new Error(error);
        }
    }

    public async logout() {
        try {
            this.isLoggedIn.next(false);
            this.cookie.deleteCookie(REFRESH_TOKEN_COOKIE);
            localStorage.removeItem('account');
            const url = `${this.endpoint}/v1/account/logout?returnUrl=${window.location.origin}`;
            window.location.href = url;
        } catch (error: any) {
            this.notification.openSnackBar(`Failed to logout (${error.message})`, 0);
            console.error(error.message);
        }
    }

    private async refresh() {
        try {
            this.authToken = '';
            const refreshToken = this.getRefreshToken();

            if (!refreshToken) {
                // User never logged in
                console.log('Auth: No token set');
                return;
            }

            const response = await this.http
                .post<v1.AccountRefreshCreate.ResponseBody>(
                    `${this.endpoint}/v1/account/refresh`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    }
                )
                .toPromise()
                .catch(() => undefined);

            if (!response) {
                // We might be offline
                if (this.accountInformation.value) {
                    // If user were logged in at some point, we have an account and may assume the user is logged in
                    this.isLoggedIn.next(true);
                    return;
                }

                throw new Error('No connection to server');
            }

            if (!response.token) {
                throw new Error('Could not log in');
            }

            this.authToken = response.token;
            this.isLoggedIn.next(true);

            await this.fetchAccountInformation();
        } catch (error: any) {
            this.isLoggedIn.next(false);
            this.authToken = '';
            console.error(error.message);
            this.notification.openSnackBar(`Failed to validate token while authenticating (${error.message})`, 0);
        }
    }

    async fetchAccountInformation() {
        try {
            const url = `${this.endpoint}/v1/account`;

            const account: AccountInformation = await this.http
                .get<v1.AccountList.ResponseBody>(url, {
                    headers: {
                        Authorization: `Bearer ${this.authToken}`,
                        Accept: 'application/json',
                    },
                })
                .toPromise();

            if (account) {
                this.aiService.setAuthenticatedUserContext(account.email, account.id);
                localStorage.setItem('account', JSON.stringify(account));
                this.accountInformation.next(account);
            }
        } catch (error: any) {
            this.isLoggedIn.next(false);
            this.authToken = '';
            console.error(error.message);
            this.notification.openSnackBar(`Failed to fetch account information while authenticating (${error.message})`, 0);
        }
    }

    public async check() {
        try {
            const query = qs.parse(window.location.search) as Record<string, string>;

            if (query.loginToken) {
                this.setRefreshToken(query.loginToken);
                this.refresh().then(() => {
                    window.location.href = window.location.origin;
                });
            } else {
                this.refresh();
            }
        } catch (error: any) {
            console.error(error.message);
        }
    }

    public clearLogin(): void {
        this.isLoggedIn.next(false);
        this.authToken = '';
    }

    private getRefreshToken(): string {
        return this.cookie.getCookie(REFRESH_TOKEN_COOKIE);
    }

    private setRefreshToken(token: string): void {
        this.cookie.setCookie(REFRESH_TOKEN_COOKIE, token, 29);
    }
}
