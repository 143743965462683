import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableModel } from '@shared/sh-table/table.model';
import { MarketingFileType } from '@models/types';
import { MarketingFileIdAndType } from '@features/marketing-files/marketing-files.component';

interface DataRowModelMarketingFile {
    applicationArea?: string | null;
    applicationAreas?: string[] | null;
    fileName?: string | null;
    mimeType?: string | null;
    marketingFileId?: MarketingFileIdAndType;
}

@Component({
    selector: 'app-sh-marketing-files-list',
    template: `
        <app-sh-table
            [tableModel]="tableModel"
            [idField]="tableModel.idField"
            [allowSort]="true"
            [isSelectable]="false"
            [hasPaginator]="false"
            [pageSize]="10"
            [pageSizeOptions]="[10, 20, 30]"
            [isTransparentHeader]="true"
            [showFirstLastButtons]="true"
            (marketingFileIdClicked)="onMarketingFileIdClicked($event)"
            (marketingFileIdClickedDownload)="onMarketingFileIdClickedDownload($event)"
        >
        </app-sh-table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShMarketingFilesListComponent {
    @Input() public set data(value: MarketingFileType[]) {
        const rowData: DataRowModelMarketingFile[] = (value || [])
            .map(({applicationAreas, fileName = '', mimeType = '', fileId = '', link = '', downloadLink = ''}) => ({
                applicationAreas,
                fileName,
                mimeType,
                marketingFileId: {
                    fileId: fileId,
                    link: link,
                    downloadLink: downloadLink,
                    mimeType: mimeType
                },
            }));
        this.tableModel = {
            headerConfig: [
                {
                    columnName: 'Application areas',
                    fieldID: 'applicationAreas',
                    disableSorting: false,
                    isMultipleApplicationAreaColumn: true,
                },
                {
                    columnName: 'File name',
                    fieldID: 'fileName',
                    disableSorting: true,
                },
                {
                    columnName: 'File type',
                    fieldID: 'mimeType',
                    disableSorting: true,
                },
                {
                    columnName: 'File link',
                    fieldID: 'marketingFileId',
                    disableSorting: true,
                    isLinkColumn: true,
                },
            ],
            idField: 'marketingFileId',
            rowData,
        };
    }
    @Output() public marketingFileIdClicked: EventEmitter<MarketingFileIdAndType> = new EventEmitter();
    @Output() public marketingFileIdClickedDownload: EventEmitter<MarketingFileIdAndType> = new EventEmitter();

    public tableModel: TableModel<DataRowModelMarketingFile>;

    public onMarketingFileIdClicked(fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClicked.emit(fileIdAndType);
    }

    public onMarketingFileIdClickedDownload(fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClickedDownload.emit(fileIdAndType);
    }
}
