import { EnvironmentType } from '@models/types';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  getEnvironment(): EnvironmentType {
    const environments: EnvironmentType[] = ['DEV', 'QA', 'PROD'];
    const host = location.host;
    let environment: EnvironmentType = 'DEV';

    // we want to be sure we are using the same domain as production
    // to not send wrong information to prod inside of other environments
    if (host === 'my.palsgaard.com') {
      return 'PROD';
    }

    for (const key of environments) {
      if (host.includes(key.toLowerCase())) {
        environment = key;
      }
    }

    return environment;
  }

}
