<div class="product-catalogue">
    <app-sh-data-filter
        class="product-catalogue__top-filters"
        [data]="products"
        [filters]="topFilters"
        (filtered)="setFiltered($event)"
    ></app-sh-data-filter>

    <app-sh-counter [count]="filtered.length"></app-sh-counter>

    <app-sh-panel>
        <app-sh-panel-content>
            <app-sh-product-list [data]="filtered"></app-sh-product-list>
        </app-sh-panel-content>
    </app-sh-panel>
</div>
