<div class="sh-select__wrapper" [class.sh-select__wrapper--brown]="isBrown">
    <div #dropreference class="sh-select__input" [class.sh-select__input--required]="required" [class.sh-select__input--has-label]="label">
        <label class="sh-select__label">{{ label }}</label>
        <input
            #input
            class="sh-select__input-field"
            [class.sh-select__input-field--has-label]="label"
            [class.sh-select__input-field--brown]="isBrown"
            [disabled]="disabled"
            readonly
            [value]="displayText"
            (keydown)="onKeyDown($event)"
            (blur)="onTouched()"
            autocomplete="off"
            [attr.aria-label]="ariaLabel"
        />
        <i class="sh-select__dropdown-arrow icon-arrow-down" (click)="onDropMenuIconClick($event)" role="button" aria-label="Open dropdown menu"></i>
        <app-sh-dropdown [reference]="dropreference" #dropdownComp>
            <div class="sh-select__dropdown-options-container" role="option" aria-label="A select option">
                <ng-content select="app-sh-select-option"></ng-content>
            </div>
        </app-sh-dropdown>
    </div>
</div>
