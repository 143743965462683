import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableLinkType } from '@models/interfaces';
import { ProductType } from '@models/types';
import { TableModel } from '@shared/sh-table/table.model';

interface DataRowModelProducts {
    id: string;
    name: TableLinkType[];
    applicationArea?: string | null;
    declaration?: string | null;
}

@Component({
    selector: 'app-sh-product-list',
    templateUrl: './sh-product-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShProductListComponent {
    @Input() public set data(value: ProductType[]) {
        // Update entire tableModel object to force change detection
        const rowData: DataRowModelProducts[] = (value || [])
            .filter(n => !!n.id)
            .map((product: ProductType) => {
                const { id, name, applicationArea, declaration } = product;

                return {
                    id: id!,
                    name: [
                        {
                            label: name || '',
                            route: `./`,
                            params: { data: product },
                            routeParams: { productId: id! },
                        },
                    ],
                    applicationArea: applicationArea?.join(', '),
                    declaration: declaration?.join(', '),
                };
            });

        this.tableModel = {
            headerConfig: [
                { columnName: 'Product', fieldID: 'name', isLinkColumn: true },
                {
                    columnName: 'Application areas',
                    fieldID: 'applicationArea',
                    disableSorting: true,
                    isApplicationAreaColumn: true,
                },
                {
                    columnName: 'Declarations',
                    fieldID: 'declaration',
                    disableSorting: true,
                },
            ],
            idField: 'id',
            rowData,
        };
    }

    public tableModel: TableModel<DataRowModelProducts>;
}
