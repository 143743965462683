<div class="solution-finder">
    <div class="sh-filter-select__container">
        <app-sh-data-filter [data]="products" [filters]="filters" (filtered)="setFiltered($event)"> </app-sh-data-filter>
    </div>

    <div #counter>
        <app-sh-counter [count]="groupedProducts.length" [initialValue]="!isFilterActive ? 'Select a filter' : ''"></app-sh-counter>
    </div>

    <ng-container *ngIf="groupedProducts.length">
        <mat-table matSort [dataSource]="dataSource" [trackBy]="trackByFn">
            <ng-container matColumnDef="content">
                <app-sh-recipe mat-cell *matCellDef="let solution" class="solution-finder__recipe" [solution]="solution"></app-sh-recipe>
            </ng-container>
            <mat-row *matRowDef="let solution; columns: ['content']"></mat-row>
        </mat-table>
    </ng-container>

    <app-sh-panel [style.display]="groupedProducts.length ? 'block' : 'none'">
        <app-sh-panel-content>
            <!-- Change main color in Angular Material theme -->
            <mat-paginator
                [pageSizeOptions]="[10, 20, 30, groupedProducts.length]"
                [length]="groupedProducts.length"
                (page)="onPageChange()"
                showFirstLastButtons
            ></mat-paginator>
        </app-sh-panel-content>
    </app-sh-panel>
</div>
