import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { EnvironmentService } from "@core/environment.service";
import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights;

    constructor(private router: Router, private location: Location, private env: EnvironmentService) {}

    public startApplicationInsights(): void {
        const { NG_APP_AI_DISABLE_AI = false, NG_APP_AI_PAGE_VISIT_TIME } = process.env;

        if (NG_APP_AI_DISABLE_AI === 'true') {
            console.info('The AI is disabled');
            return;
        }

        const angularPlugin = new AngularPlugin();
        const config: IConfiguration & IConfig = {
            appId: 'MyPalsgaard',
            namePrefix: 'my-palsgaard-ai-',
            connectionString: this.getEnvironmentVariable(true),
            instrumentationKey: this.getEnvironmentVariable(),
            enableAutoRouteTracking: false,
            autoTrackPageVisitTime: NG_APP_AI_PAGE_VISIT_TIME === 'true',
            extensions: [angularPlugin],
            extensionConfig: {
                [angularPlugin.identifier]: { router: this.router },
            },
        };

        this.appInsights = new ApplicationInsights({ config });
        console.info('env:', this.env.getEnvironment());

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    public setAuthenticatedUserContext(email: string, userId?: string): void {
        if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(email, userId, true);
        }
    }

    public clearAuthenticatedUserContext(): void {
        if (this.appInsights) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    private getEnvironmentVariable(useConnectionString = false): string {
        const env = this.env.getEnvironment();

        if (env === 'QA') {
            return useConnectionString ? process.env.NG_APP_AI_CONNECTION_STRING_QA : process.env.NG_APP_AI_INSTRUMENTATION_KEY_QA;
        }

        if (env === 'PROD') {
            return useConnectionString ? process.env.NG_APP_AI_CONNECTION_STRING_PROD : process.env.NG_APP_AI_INSTRUMENTATION_KEY_PROD;
        }

        return useConnectionString ? process.env.NG_APP_AI_CONNECTION_STRING_DEV : process.env.NG_APP_AI_INSTRUMENTATION_KEY_DEV;
    }
}
