<div class="solution-finder__recipe-content">
    <div class="solution-finder__recipe-header">
        <div class="solution-finder__recipe-header-title">
            <span class="solution-finder__recipe-header-title-text">
                {{ solution.name }}
                <br />
            </span>
        </div>
    </div>

    <table class="solution-finder__recipe-table">
        <thead>
            <tr>
                <th class="solution-finder__recipe-table-cell solution-finder__recipe-table-header">
                    <h3>Recipe</h3>
                </th>
                <th class="solution-finder__recipe-table-cell solution-finder__recipe-table-header">
                    <h3>Products</h3>
                </th>
            </tr>
        </thead>

        <tbody class="solution-finder__recipe-table-body">
            <tr class="sh-table__content-row" *ngFor="let solution of solutionsSorted | async">
                <td class="solution-finder__recipe-table-cell">
                    <a
                        class="sh-button__link sh-button__link--has-partition"
                        href="/api/v1/files?fileId={{ solution.recipe.recipeFile.fileId }}"
                        target="_blank"
                        (click)="openRecipePdf($event, solution.recipe.recipeFile)"
                    >
                        Open recipe
                    </a>
                </td>
                <td class="solution-finder__recipe-table-cell">
                    <ng-container *ngFor="let product of solution.products; let last = last">
                        <a
                            *ngIf="product?.name && product?.id"
                            class="sh-button__link--has-partition"
                            [routerLink]="'/products'"
                            [queryParams]="{
                                productId: product.id
                            }"
                        >
                            <button shButton type="link" textAlign="left" size="small" [hasPartition]="true">
                                {{ product.name }}
                            </button>
                        </a>
                        <span *ngIf="!last">/</span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
