import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage.service';
import { environment } from '@environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import axios from 'axios';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslationLoaderService implements TranslateLoader {
    constructor(private storage: StorageService) {}

    async fetchTranslations(langKey: string) {
        try {
            let data = await this.storage.getTranslations(langKey);

            if (!data) {
                const { translationUrl } = environment;
                const res = await axios.get(`${translationUrl}/${langKey}`);
                data = res.data;
                this.storage.setData('translations', data, langKey);
            }

            return data;
        } catch (error: any) {
            throw error;
        }
    }

    getTranslation(langKey: string): Observable<any> {
        return from(this.fetchTranslations(langKey));
    }
}
