import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sh-dropdown',
    template: `
        <ng-template cdkPortal>
            <ng-content></ng-content>
        </ng-template>
    `,
})
export class ShDropdownComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input()
    public reference: HTMLElement;

    @ViewChild(CdkPortal)
    public contentTemplate: CdkPortal;

    protected overlayRef: OverlayRef;

    public showing = false;

    constructor(protected overlay: Overlay) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public show(): void {
        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        this.overlayRef.attach(this.contentTemplate);
        this.syncWidth();
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.hide());
        this.showing = true;
    }

    public hide(): void {
        this.overlayRef.detach();
        this.showing = false;
    }

    @HostListener('window:resize')
    public onWinResize(): void {
        this.syncWidth();
    }

    private getOverlayConfig(): OverlayConfig {
        const posStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.reference)
            .withPush(false)
            .withPositions([
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                },
            ]);

        const scrollStrat = this.overlay.scrollStrategies.reposition();

        return {
            positionStrategy: posStrategy,
            scrollStrategy: scrollStrat,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
        };
    }

    private syncWidth(): void {
        if (!this.overlayRef) {
            return;
        }

        const refRect = this.reference.getBoundingClientRect();
        this.overlayRef.updateSize({ width: refRect.width });
    }
}
