import { Component, HostBinding, HostListener, Input } from '@angular/core';

import { ShSelectComponent } from './sh-select.component';
import { ShDropdownService } from './sh-select.service';

@Component({
    selector: 'app-sh-select-option',
    template: '{{value}}',
})
export class ShSelectOptionComponent {
    @Input()
    public key?: string;
    @Input()
    public value?: string;
    @Input()
    public isBrown = false;
    @HostBinding('class.sh-select__item--brown')
    get brownClass() {
        return this.isBrown;
    }
    @HostBinding('class.sh-select__item')
    public defaultClass = true;

    private select: ShSelectComponent;

    constructor(private dropdownService: ShDropdownService) {
        this.select = this.dropdownService.getSelect();
    }

    public getLabel(): string {
        return this.value || '';
    }

    @HostListener('click', ['$event'])
    public onClick(event: UIEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.select.selectOption(this);
    }
}
