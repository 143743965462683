<div class="sh-table-paginator">
    <ul class="sh-table-paginator__container">
        <li *ngIf="showFirstLastButtons" class="sh-table-paginator__page-item" (click)="firstPage()">
            <i class="icon-previous"></i>
            <i class="icon-previous"></i>
        </li>
        <li class="sh-table-paginator__page-item" (click)="previousPage()">
            <i class="icon-previous"></i>
        </li>
        <li
            *ngFor="let page of pages"
            class="sh-table-paginator__page-item"
            [ngClass]="{
                'sh-table-paginator__page-item--active': page === pageIndex,
                'sh-table-paginator__page-item--hide': page < minPage || page > maxPage
            }"
            (click)="onPageClick(page)"
        >
            <span>
                {{ page }}
            </span>
        </li>
        <li class="sh-table-paginator__page-item" (click)="nextPage()">
            <i class="icon-next"></i>
        </li>
        <li *ngIf="showFirstLastButtons" class="sh-table-paginator__page-item" (click)="lastPage()">
            <i class="icon-next"></i>
            <i class="icon-next"></i>
        </li>
    </ul>
    <div class="sh-table-paginator__page-size-selector" *ngIf="!hidePageSize && pageSizeOptions.length">
        <app-sh-select [selected]="pageSize" (changeEvent)="onPageSizeChange($event)">
            <app-sh-select-option *ngFor="let size of pageSizeOptions" [key]="size" [value]="size"></app-sh-select-option>
        </app-sh-select>
    </div>
</div>
