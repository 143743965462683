<nav>
    <h1 class="element-hidden">Tab navigation</h1>
    <ul class="tabs" role="tablist">
        <li class="tabs__item" *ngFor="let tab of tabData">
            <a role="tab" (click)="onTabChange(tab)" class="tabs__item__target" [class.tabs__item__target--active]="tab.id === activeTab.id">
                {{ tab.name }}
            </a>
        </li>
    </ul>
</nav>
