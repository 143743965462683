<app-sh-panel>
    <app-sh-panel-content>
        <div class="userguide__container">
            <h1 class="text__heading text__heading--paragraph">Welcome to MyPalsgaard</h1>

            <div class="text__paragraph-container">
                <p class="text__paragraph">Please Bookmark this address in your browser to find it easily every time.</p>
                <p class="text__paragraph">
                    MyPalsgaard works best on your PC/Laptop but will also work nicely on your tablet/iPad through a browser. We recommend Chrome, Safari, or Edge as suitable browsers.
                </p>
                <p class="text__paragraph">On this platform, you will have access to our Product Catalogue, our Solution Finder, and Marketing files.</p>
                <p class="text__paragraph">
                    The Product Catalogue contains all available products and relevant documentation.
                </p>
                <p class="text__paragraph">
                    The Solution Finder guides you through application needs and presents relevant recipes and related products.
                </p>
                <p class="text__paragraph">
                    In Marketing files, you will find our product brochures, presentations, and as a new feature, also our training videos.
                </p>
            </div>

            <section class="text__paragraph-container">
                <h2 class="text__heading text__heading--paragraph">Use of MyPalsgaard:</h2>
                <p class="text__paragraph">
                    The information provided in MyPalsgaard is as accurate as possible. However, errors in the data may occur on occasion. We strive to update and improve the data quality continually. If you spot any errors or got another question, please let us know:
                    <a href="mailto: psh@palsgaard.dk">psh@palsgaard.dk</a>
                </p>
                <p class="text__paragraph">
                    As always, we recommend that you contact your local Palsgaard product specialist should there be any questions about Palsgaard products and solutions.
                </p>
            </section>

            <section class="text__paragraph-container">
                <h2 class="text__heading text__heading--paragraph">Clarification of terms used:</h2>
                <p class="text__paragraph">When using the MyPalsgaard search filters, the following definitions are used:</p>
                <br />
                <p class="text__paragraph"><strong>Kosher:</strong> These products are certified Kosher.</p>
                <p class="text__paragraph"><strong>Halal:</strong> These products are certified Halal.</p>
                <p class="text__paragraph"><strong>Non Palm:</strong> These products do not contain ingredients based on palm oil.</p>
                <p class="text__paragraph"><strong>SG:</strong> These products are available on request as RSS SCCS Segregated Palm Oil certified.</p>
                <p class="text__paragraph"><strong>Non PHO:</strong> These products do not contain Partially Hydrogenated Oils (PHO).</p>
                <p class="text__paragraph"><strong>Vegetable:</strong> These products do not contain dairy or animal derivatives.</p>
            </section>

            <section class="text__paragraph-container">
                <h2 class="text__heading text__heading--paragraph">Further Notes:</h2>
                <div class="text__paragraph">
                    <ul class="list">
                        <li>Products from PAMY are available only as Mass Balance "MB" (not SG certified) at present (Jan 2021)</li>
                        <li>
                            SG products can only be delivered by Palsgaard offices which are either SG certified or have an SG license. Please check
                            with your local Palsgaard representative.
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </app-sh-panel-content>
</app-sh-panel>
