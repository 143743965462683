import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@core/authentication.service';
import { staticPortals } from '@models/constants';
import { Portal } from '@models/interfaces';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PortalsService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    private readonly portals$ = new ReplaySubject<Portal[]>(1);
    public readonly portals = this.portals$.asObservable();
    private readonly currentPortal = new BehaviorSubject<{
        portal: string;
        skipNavigation?: boolean;
    }>({ portal: '', skipNavigation: true });
    public readonly portalObs = this.currentPortal.asObservable();

    constructor(readonly auth: AuthenticationService) {
        this.auth.accountInformation.pipe(takeUntil(this.unsubscribe)).subscribe(account => {
            if (!account) {
                return;
            }

            let portals: Portal[] = [];
            if (account.portals?.length) {
                portals = staticPortals.filter(p => account.portals.includes(p.id));
            } else {
                portals = staticPortals;
            }

            this.portals$.next(portals);

            let currentPortal: string = this.getStoredPortal();

            // Validate stored portal
            if (!currentPortal || !portals.find(x => x.id === currentPortal)) {
                currentPortal = portals[0].id;
            }

            console.log('initial portal', currentPortal);
            this.setPortal(currentPortal, true);
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public setPortal(portal: string, skipNavigation = false) {
        console.log('setting new portal', portal);
        this.currentPortal.next({ portal, skipNavigation });
        if (window && 'Storage' in window) {
            localStorage.setItem('portal', portal);
        }
    }

    public getCurrentPortal(): string {
        return this.currentPortal.value.portal;
    }

    public getCurrentPortalName() {
        const currentPortal = this.getCurrentPortal();
        return staticPortals.find(p => p.id === currentPortal)?.value || 'N/A';
    }

    private getStoredPortal(): string {
        return window && 'Storage' in window ? localStorage.getItem('portal') || '' : '';
    }
}
