import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationType } from '@models/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private _snackBar: MatSnackBar) {}
    initialState: NotificationType = {
        message: '',
        complete: false,
        error: '',
    };

    notification = new BehaviorSubject<NotificationType>(this.initialState);

    showNotification(data: NotificationType) {
        if (data && data.message) {
            if (data.overlay) {
                this.notification.next(data);
            } else {
                this.openSnackBar(data.message);
            }
        }
        if (data.error) {
            console.log(data.error);
        }
    }

    openSnackBar(message: string, duration = 5000, action = 'close') {
        // To do: add translation instead of 'close'
        return this._snackBar.open(message, action, {
            duration,
        });
    }
}
